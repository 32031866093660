/* eslint-disable max-len, sort-keys */
const keywords = [
  {name: "Adversity"},
  {name: "Aging"},
  {name: "Angels"},
  {name: "Beauty"},
  {name: "Birds"},
  {name: "Change"},
  {name: "Childhood"},
  {name: "Comfort"},
  {name: "Courage"},
  {name: "Death"},
  {name: "Deception"},
  {name: "Destiny"},
  {name: "Dying"},
  {name: "Depression"},
  {name: "Dreams"},
  {name: "Dying"},
  {name: "Earth"},
  {name: "Emotions"},
  {name: "Encouraging"},
  {name: "Faith"},
  {name: "Fame"},
  {name: "Fate"},
  {name: "Fear"},
  {name: "Flowers"},
  {name: "Forgiveness"},
  {name: "Freedom"},
  {name: "Friendship"},
  {name: "Funerals"},
  {name: "God"},
  {name: "Gratitude"},
  {name: "Grief"},
  {name: "Happiness"},
  {name: "Hate"},
  {name: "Heartbreak"},
  {name: "Heaven"},
  {name: "Hope"},
  {name: "Humility"},
  {name: "Identity"},
  {name: "Imagination"},
  {name: "Impossibility"},
  {name: "Innocence"},
  {name: "Inner Peace"},
  {name: "Inspiration"},
  {name: "Jealousy"},
  {name: "Joy"},
  {name: "Justice"},
  {name: "Knowledge"},
  {name: "Life"},
  {name: "Love"},
  {name: "Marriage"},
  {name: "Memories"},
  {name: "Mountains"},
  {name: "Mourning"},
  {name: "Music"},
  {name: "Nature"},
  {name: "New Year"},
  {name: "Oneness"},
  {name: "Opportunity"},
  {name: "Overcoming"},
  {name: "Pain"},
  {name: "Patience"},
  {name: "Peace"},
  {name: "Perseverance"},
  {name: "Prayer"},
  {name: "Regret"},
  {name: "Revenge"},
  {name: "Roses"},
  {name: "Sadness"},
  {name: "Simplicity"},
  {name: "Sin"},
  {name: "Soul"},
  {name: "Stars"},
  {name: "Suffering"},
  {name: "Sympathy"},
  {name: "Technology"},
  {name: "Time"},
  {name: "Trees"},
  {name: "Trust"},
  {name: "War"},
  {name: "Work"},
];

export default keywords;