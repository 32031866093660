import React, {Component} from 'react';

class Toggle extends Component {
	constructor(props) {
		super(props);
		this.toggleState = this.toggleState.bind(this);
		this.handleToggleClick = this.handleToggleClick.bind(this);
	}
  
	handleToggleClick(e) {
		this.props.handleToggleClick(e, this.props.name);
	}
	toggleState() {
		this.setState({
			toggle: !this.props.toggle
		});
	}

	render() {
		return (
			<form className={`${this.props.type} switch-field `} >
				<input
					type="radio"
					name={this.props.name}
					value={this.props.leftLabel}
					onChange={this.toggleState}
					checked={!this.props.toggle}
				/>
				<label htmlFor="switch_left" className={`switch-left-${this.props.type}`} onClick={this.handleToggleClick}>{this.props.leftLabel}</label>
				<input
					type="radio"
					name={this.props.name}
					value={this.props.rightLabel}
					onChange={this.toggleState}

					checked={this.props.toggle}
				/>
				<label htmlFor="switch_right" className={`switch-left-${this.props.type}`} onClick={this.handleToggleClick}>{this.props.rightLabel}</label>
			</form>
		);
	}
}

export default Toggle;