import React from 'react';
import Results from './Results'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

class ResultsGallery extends React.Component {
  render() {
    return (
      <QueryClientProvider client={queryClient} key="results-query">
        <Results searchParams={this.props.searchParams}/>
      </QueryClientProvider>
    );
  }
};

export default ResultsGallery;
