import React, { Fragment } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';
import genres from '../Data/genres';
import 'react-bootstrap-typeahead/css/Typeahead.css';


class TypeaheadGenre extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(e) {
    this.props.onGenreChange(e);
  }

  render() {
    const selected = this.props.genres;
    const className = this.props.matchAny ? 'typeahead-genre-any' : 'typeahead-genre'
    return (
      <Fragment>
        <Form.Group style={{ marginTop: '20px' }}>
          <Typeahead
            allowNew
            id="basic-typeahead-multiple genre"
            labelKey="name"
            multiple
            onChange={this.handleChange}
            options={genres}
            placeholder="Choose genre..."
            selected={selected}
            className={className}
            newSelectionPrefix="Add a new filter: "
            ignoreDiacritics={true}
          />
        </Form.Group>
      </Fragment>
    );
  }
};

export default TypeaheadGenre;
