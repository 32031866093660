import React from 'react';


class SearchFilters extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.createSearchFilterElement = this.createSearchFilterElement.bind(this);
  }
  
  handleClick(e) {
    this.props.onClick(e);
  }

  createSearchFilterElement(text, type, matchAny, isLastElement) {
    const classType = matchAny ? `${type}-any` : type
    const className = `search-filter ${classType} rbt-token-removeable primary-font-light`;
    const searchFilterKey = `search-filter-${type}-${text}`;
    const buttonValue = `${text}-${type}`
    return (
      <span key={searchFilterKey} >
      <div className={className} onClick={this.handleClick} tabIndex="0">
        {text}
        <button value={buttonValue} type='button' tabIndex="-1" aria-label="Remove" className="close rbt-close search-filter-remove-button" >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Remove</span>
        </button>
      </div>
      <div className='primary-font-medium' style={{display: 'inline-block', marginLeft: '2px', marginRight: '2px', fontSize: '10px'}}>
        {matchAny && !isLastElement ? 'OR' : ''}
      </div>
      </span>
    )
  }

  render() {
    const genres = this.props.genres || [];
    const authors = this.props.authors || [];
    const keywords = this.props.keywords || [];

    const matchAnyGenre = this.props.matchAnyGenre
    const matchAnyAuthor = this.props.matchAnyAuthor
    const matchAnyKeyword = this.props.matchAnyKeyword

    const genreElements = genres.map((genre, i) => this.createSearchFilterElement(genre.name, 'genres', matchAnyGenre, i === (genres.length - 1)))
    const authorElements = authors.map((author, i) => this.createSearchFilterElement(author.name, 'authors', matchAnyAuthor, i === (authors.length - 1)))
    const keywordElements = keywords.map((keyword, i) => this.createSearchFilterElement(keyword.name, 'keywords', matchAnyKeyword, i === (keywords.length - 1)))
    
    const searchFilterElements = genreElements.concat(authorElements).concat(keywordElements)
    return (
      <div className="search-filters">
        {searchFilterElements}
      </div>
    );
  }
};

export default SearchFilters;
