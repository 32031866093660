// Table.js

import React, { useState, useCallback } from "react";
import { useTable, useGlobalFilter } from "react-table";

export default function ResourceTable({ columns, data }) {
	// Create a state
	const [filterInput, setFilterInput] = useState("");

	// Update the state when input changes
	const handleFilterChange = e => {
	  const value = e.target.value || '';
	  setGlobalFilter(value); // Update the show.name filter. Now our table will filter and show only the rows which have a matching value
	  setFilterInput(value);
	};


	const resourceGlobalFilter = useCallback(
	    (rows, ids, query) => {
	        return rows.filter((row) => 
	            row.values['resource.site']['name'].toLowerCase().includes(query.toLowerCase()) ||
	            row.values['resource.type'].toLowerCase().includes(query.toLowerCase()) ||
	            row.values['resource.language'].toLowerCase().includes(query.toLowerCase()) ||
	            row.values['resource.authors'].some(author => author.toLowerCase().includes(query.toLowerCase())) ||
	            row.values['resource.genres'].some(genre => genre.toLowerCase().includes(query.toLowerCase()))
	        );
	    },
	    [],
	);

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setGlobalFilter // The useFilter Hook provides a way to set the filter
  } = useTable({
    columns,
    data,
    globalFilter: resourceGlobalFilter
  },
  	useGlobalFilter // Adding the useGlobalFilter Hook to the table
  );

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (

  	<div className='resource-table'>
      <div className='resource-intro'>
        <p className="secondary-font title">Resources</p>
{/*        <p className="primary-font-medium">
          This directory aims to help collect the many incredible initiatives promoting and supporting diverse storytellers. Hopefully, the list below serves as a useful guide to the many resources out there. The list will continue to grow, so if you have a suggestion to add a missing resource, please contact us at <span className='email'>books.storie.media@gmail.com</span>
        </p>*/}
      </div>
      <label className='resource-search-bar'>
        <input
      		className='resource-input primary-font-light'
      		value={filterInput}
      		onChange={handleFilterChange}
      		placeholder={"Filter by search term..."}
        />
    	</label>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr className='secondary-font' key={i}>
              {headerGroup.headers.map((column, j) => (
                <th {...column.getHeaderProps()} key={j}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className='primary-font-medium'>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return <td {...cell.getCellProps()} key={j}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}


