/* eslint-disable max-len, sort-keys */
const authors = [
  {name: 'African-American'},
  {name: 'Arab'},
  {name: 'Asexual'},
  {name: 'Basque'},
  {name: 'Bengali'},
  {name: 'Bisexual'},
  {name: 'Black British'},
  {name: 'Bosniak'},
  {name: 'Breton'},
  {name: 'Burgher'},
  {name: 'Caribbean'},
  {name: 'Catalan'},
  {name: 'Chechen'},
  {name: 'Czechoslovak'},
  {name: 'Dominica'},
  {name: 'Galician'},
  {name: 'Gay'},
  {name: 'Hindus'},
  {name: 'Hispanic and Latino'},
  {name: 'Hong Kong'},
  {name: 'Igbo'},
  {name: 'Kashmiri'},
  {name: 'Korean'},
  {name: 'LGBTQIA+'},
  {name: 'Lesbian'},
  {name: 'Mestizo'},
  {name: 'Middle Eastern'},
  {name: 'Native American'},
  {name: 'Native Hawaiian'},
  {name: 'New Zealand Māori'},
  {name: 'Non-binary'},
  {name: 'Queer'},
  {name: 'Sinhalese'},
  {name: 'South African'},
  {name: 'Tamil'},
  {name: 'Tibetan'},
  {name: 'Yoruba'}
]

// const authors = [
//   {name: "Abazin"},
//   {name: "Abenaki"},
//   {name: "Abkhaz"},
//   {name: "Aboriginal Australian"},
//   {name: "Abron"},
//   {name: "Abyssinian"},
//   {name: "Acadian"},
//   {name: "Accohannock"},
//   {name: "Acehnese"},
//   {name: "Achang"},
//   {name: "Acholi"},
//   {name: "Achomawi"},
//   {name: "Acoma"},
//   {name: "Adi"},
//   {name: "Adyghe"},
//   {name: "Aeta"},
//   {name: "Afar"},
//   {name: "African American"},
//   {name: "African Hebrew Israelite"},
//   {name: "Afrikaner"},
//   {name: "Agaw"},
//   {name: "Agul"},
//   {name: "Ahtna"},
//   {name: "Aimaq"},
//   {name: "Aja"},
//   {name: "Ak Chin"},
//   {name: "Aka"},
//   {name: "Akan"},
//   {name: "Akha"},
//   {name: "Akhvakh"},
//   {name: "Akie"},
//   {name: "Akuapem"},
//   {name: "Akyem"},
//   {name: "Alabama"},
//   {name: "Alak"},
//   {name: "Alawite"},
//   {name: "Albanian"},
//   {name: "Aleut"},
//   {name: "Algonquian"},
//   {name: "Aliutor"},
//   {name: "Alsatian"},
//   {name: "Altaian"},
//   {name: "Amahuaca"},
//   {name: "Amerasian"},
//   {name: "American"},
//   {name: "Americo-Liberian"},
//   {name: "Amhara"},
//   {name: "Amish"},
//   {name: "Amungme"},
//   {name: "Andalusian"},
//   {name: "Andi"},
//   {name: "Anga"},
//   {name: "Anglo-Burmese"},
//   {name: "Anglo-Celtic Australian"},
//   {name: "Annamite"},
//   {name: "Anuak"},
//   {name: "Anyi"},
//   {name: "Apache"},
//   {name: "Apinaje"},
//   {name: "Arab"},
//   {name: "Aragonese"},
//   {name: "Aramean-Syriac"},
//   {name: "Arapaho"},
//   {name: "Arawak"},
//   {name: "Arbëreshë"},
//   {name: "Archi"},
//   {name: "Arikara"},
//   {name: "Armenian"},
//   {name: "Aromanian"},
//   {name: "Arvanite"},
//   {name: "Asante"},
//   {name: "Asheninka"},
//   {name: "Ashkenazi Jew"},
//   {name: "Asian American"},
//   {name: "Asmat"},
//   {name: "Assiniboine"},
//   {name: "Assyrian"},
//   {name: "Asturian"},
//   {name: "Atacameno"},
//   {name: "Ati"},
//   {name: "Atikamekw"},
//   {name: "Atoni"},
//   {name: "Atsina"},
//   {name: "Atsugewi"},
//   {name: "Aukstaitian"},
//   {name: "Austrian"},
//   {name: "Avar"},
//   {name: "Awá"},
//   {name: "Aymara"},
//   {name: "Aynu"},
//   {name: "Ayrum"},
//   {name: "Azerbaijani"},
//   {name: "Aztec"},
//   {name: "Ba Na"},
//   {name: "Babongo"},
//   {name: "Badui"},
//   {name: "Baggara"},
//   {name: "Baguirmi"},
//   {name: "Bagulal"},
//   {name: "Bahraini"},
//   {name: "Bai"},
//   {name: "Bai"},
//   {name: "Bajau"},
//   {name: "Baka"},
//   {name: "Bakhtiari"},
//   {name: "Bakongo"},
//   {name: "Balinese"},
//   {name: "Balkar"},
//   {name: "Baloch"},
//   {name: "Baltic German"},
//   {name: "Bamar"},
//   {name: "Bambara"},
//   {name: "Bamileke"},
//   {name: "Banat Swabian"},
//   {name: "Banawa"},
//   {name: "Banda"},
//   {name: "Bandjabi"},
//   {name: "Banjar"},
//   {name: "Baoule"},
//   {name: "Bapou"},
//   {name: "Barakzai"},
//   {name: "Bariba"},
//   {name: "Bartang"},
//   {name: "Basarwa"},
//   {name: "Bashkir"},
//   {name: "Basotho"},
//   {name: "Basque"},
//   {name: "Bassa"},
//   {name: "Bassari"},
//   {name: "Baster"},
//   {name: "Batak"},
//   {name: "Batak"},
//   {name: "Bateke"},
//   {name: "Bat"},
//   {name: "Batswana"},
//   {name: "Bavarian"},
//   {name: "Bedouin"},
//   {name: "Beja"},
//   {name: "Belarusian"},
//   {name: "Bemba"},
//   {name: "Bene Israel"},
//   {name: "Bengali"},
//   {name: "Berber"},
//   {name: "Berom"},
//   {name: "Betamaribe"},
//   {name: "Bethio"},
//   {name: "Beti-Pahuin"},
//   {name: "Bezhta"},
//   {name: "Bhil"},
//   {name: "Bhotiya"},
//   {name: "Biafada"},
//   {name: "Bicolano"},
//   {name: "Bihari"},
//   {name: "Black British"},
//   {name: "Black Indian"},
//   {name: "Blackfeet"},
//   {name: "Bodo"},
//   {name: "Bonairean"},
//   {name: "Bonan"},
//   {name: "Bosniak"},
//   {name: "Botlikh"},
//   {name: "Bougainvillean"},
//   {name: "Bouyei"},
//   {name: "Boyar"},
//   {name: "Boyko"},
//   {name: "Bozo"},
//   {name: "Brahui"},
//   {name: "Brau"},
//   {name: "Breton"},
//   {name: "Bru-Van Kieu"},
//   {name: "Brulé"},
//   {name: "Bubi"},
//   {name: "Budukh"},
//   {name: "Bugi"},
//   {name: "Bulang"},
//   {name: "Bulgarian"},
//   {name: "Bunjevci"},
//   {name: "Burgenland Croat"},
//   {name: "Buryat"},
//   {name: "Bushongo"},
//   {name: "Caddo"},
//   {name: "Cahuilla"},
//   {name: "Caingang"},
//   {name: "Cajun"},
//   {name: "Caldoche"},
//   {name: "Californio"},
//   {name: "Campa"},
//   {name: "Canarian"},
//   {name: "Cantonese"},
//   {name: "Cape Coloured"},
//   {name: "Cape Malay"},
//   {name: "Caprivian"},
//   {name: "Carib"},
//   {name: "Carinthian Slovene"},
//   {name: "Castilian"},
//   {name: "Catalan"},
//   {name: "Catawba"},
//   {name: "Cayuga"},
//   {name: "Cebuano"},
//   {name: "Ceylon Moor"},
//   {name: "Chagga"},
//   {name: "Cham"},
//   {name: "Chamalal"},
//   {name: "Chambri"},
//   {name: "Chamorro"},
//   {name: "Champenoi"},
//   {name: "Chechen"},
//   {name: "Chehali"},
//   {name: "Chemehuevi"},
//   {name: "Chepang"},
//   {name: "Cherokee"},
//   {name: "Cheyenne"},
//   {name: "Chicano"},
//   {name: "Chickahominy"},
//   {name: "Chickasaw"},
//   {name: "Chilcotin"},
//   {name: "Chilean"},
//   {name: "Chinookan"},
//   {name: "Chipewyan"},
//   {name: "Chippewa"},
//   {name: "Chitimacha"},
//   {name: "Chitpavan"},
//   {name: "Cho Ro"},
//   {name: "Choctaw"},
//   {name: "Chukchansi"},
//   {name: "Chukchi"},
//   {name: "Chulym Tatar"},
//   {name: "Chumash"},
//   {name: "Chuncho"},
//   {name: "Chut"},
//   {name: "Chuukese"},
//   {name: "Chuvash"},
//   {name: "Clayoquot"},
//   {name: "Co Ho"},
//   {name: "Co Tu"},
//   {name: "Coalhuiltec"},
//   {name: "Coast Salish"},
//   {name: "Cochiti"},
//   {name: "Cocopah"},
//   {name: "Coeur d'Alene"},
//   {name: "Coharie"},
//   {name: "Coloured"},
//   {name: "Colville"},
//   {name: "Comanche"},
//   {name: "Comorian"},
//   {name: "Cong"},
//   {name: "Congolese"},
//   {name: "Copper"},
//   {name: "Copt"},
//   {name: "Coquille"},
//   {name: "Cornish"},
//   {name: "Cornish American"},
//   {name: "Cornish Australian"},
//   {name: "Cossack"},
//   {name: "Costanoan"},
//   {name: "Coushatta"},
//   {name: "Cowichan"},
//   {name: "Cowlitz"},
//   {name: "Cree"},
//   {name: "Creek"},
//   {name: "Creole Belizean"},
//   {name: "Crimean German"},
//   {name: "Crimean Tatar"},
//   {name: "Croat"},
//   {name: "Crow"},
//   {name: "Créole"},
//   {name: "Cuban"},
//   {name: "Cupeño"},
//   {name: "Curaçaoan"},
//   {name: "Czech"},
//   {name: "Daasanach"},
//   {name: "Dadhich"},
//   {name: "Dai"},
//   {name: "Dakelh"},
//   {name: "Dakota"},
//   {name: "Damara"},
//   {name: "Dane"},
//   {name: "Danezaa"},
//   {name: "Danmin"},
//   {name: "Dargin"},
//   {name: "Darhad"},
//   {name: "Daribi"},
//   {name: "Daur"},
//   {name: "Dayak"},
//   {name: "De'ang"},
//   {name: "Deg Hit'an"},
//   {name: "Degar"},
//   {name: "Delaware"},
//   {name: "Dena'ina"},
//   {name: "Dendi"},
//   {name: "Derbish"},
//   {name: "Desana"},
//   {name: "Dhivehi"},
//   {name: "Dhodia"},
//   {name: "Dido"},
//   {name: "Diegueno"},
//   {name: "Dinka"},
//   {name: "Diola"},
//   {name: "Dogon"},
//   {name: "Dolgan"},
//   {name: "Doma"},
//   {name: "Dominican American"},
//   {name: "Dominican"},
//   {name: "Don Cossack"},
//   {name: "Dong"},
//   {name: "Dongxiang"},
//   {name: "Dorze"},
//   {name: "Drung"},
//   {name: "Druze"},
//   {name: "Du"},
//   {name: "Duala"},
//   {name: "Dungan"},
//   {name: "Dutch"},
//   {name: "Dyula"},
//   {name: "Ebira"},
//   {name: "Ecuadorian"},
//   {name: "Elema"},
//   {name: "Enet"},
//   {name: "Enga"},
//   {name: "English"},
//   {name: "Enxet"},
//   {name: "Eshira"},
//   {name: "Estonian"},
//   {name: "European American"},
//   {name: "Evenki"},
//   {name: "Even"},
//   {name: "Ewe"},
//   {name: "Falasha/Beta Israel"},
//   {name: "Fante"},
//   {name: "Faroese"},
//   {name: "Fereydan"},
//   {name: "Fernandino"},
//   {name: "Fijian"},
//   {name: "Finn"},
//   {name: "Flemish"},
//   {name: "Fon"},
//   {name: "Fox"},
//   {name: "Franco-Mauritian"},
//   {name: "Franco-Réunionnaise"},
//   {name: "Franconian"},
//   {name: "French"},
//   {name: "Frisian"},
//   {name: "Fula"},
//   {name: "Fulni-o"},
//   {name: "Fur"},
//   {name: "Ga-Adangbe"},
//   {name: "Gaddi"},
//   {name: "Gael"},
//   {name: "Gagauz"},
//   {name: "Galician"},
//   {name: "Gaoshan"},
//   {name: "Garifuna/Garinagu"},
//   {name: "Garo"},
//   {name: "Gbaya"},
//   {name: "Ge"},
//   {name: "Geba Buru"},
//   {name: "Gelao"},
//   {name: "Georgian"},
//   {name: "German"},
//   {name: "Gia Rai"},
//   {name: "Giay"},
//   {name: "Gibraltarian"},
//   {name: "Gie Trieng"},
//   {name: "Godoberi"},
//   {name: "Gogodali"},
//   {name: "Gongduk"},
//   {name: "Goral"},
//   {name: "Gorani"},
//   {name: "Goshute"},
//   {name: "Gotlander"},
//   {name: "Goulaye"},
//   {name: "Greek Cypriot"},
//   {name: "Greek"},
//   {name: "Griqua"},
//   {name: "Gros Ventre"},
//   {name: "Gruzinim"},
//   {name: "Guadeloupean"},
//   {name: "Guajajara"},
//   {name: "Guarani"},
//   {name: "Gujarati"},
//   {name: "Gullah"},
//   {name: "Gurage"},
//   {name: "Guria"},
//   {name: "Guru"},
//   {name: "Gurung"},
//   {name: "H're"},
//   {name: "Hadza"},
//   {name: "Haida"},
//   {name: "Haitian"},
//   {name: "Hakka"},
//   {name: "Haliwa-Saponi"},
//   {name: "Hamar"},
//   {name: "Hamsheni"},
//   {name: "Han Chinese"},
//   {name: "Hani"},
//   {name: "Hapa"},
//   {name: "Hasani"},
//   {name: "Hausa"},
//   {name: "Havasupai"},
//   {name: "Haw"},
//   {name: "Hawaiian"},
//   {name: "Hazara"},
//   {name: "Herero"},
//   {name: "Hesquiat"},
//   {name: "Hezhen"},
//   {name: "Hidatsa"},
//   {name: "Himba"},
//   {name: "Hindi"},
//   {name: "Hindoestanen"},
//   {name: "Hinukh"},
//   {name: "Hispanic"},
//   {name: "Hmar"},
//   {name: "Hmong"},
//   {name: "Ho-Chunk"},
//   {name: "Hoa"},
//   {name: "Hoh"},
//   {name: "Hoklo"},
//   {name: "Holikachuk"},
//   {name: "Hopi"},
//   {name: "Houma"},
//   {name: "Hualapai"},
//   {name: "Huastec"},
//   {name: "Hui"},
//   {name: "Huichol"},
//   {name: "Huli"},
//   {name: "Hungarian"},
//   {name: "Hunzakut"},
//   {name: "Hunzib"},
//   {name: "Hupa"},
//   {name: "Huron"},
//   {name: "Hutsul"},
//   {name: "Hutu"},
//   {name: "Hykso"},
//   {name: "I-Kiribati"},
//   {name: "Iatmul"},
//   {name: "Iban"},
//   {name: "Ibanag"},
//   {name: "Ibibio"},
//   {name: "Icelander"},
//   {name: "Igbo"},
//   {name: "Igorot"},
//   {name: "Ijaw"},
//   {name: "Illiniwek"},
//   {name: "Ilocano"},
//   {name: "Ilonggo"},
//   {name: "Imereti"},
//   {name: "Inca"},
//   {name: "Indo-Caribbean"},
//   {name: "Indo-Guyanese"},
//   {name: "Indo-Jamaican"},
//   {name: "Indo-Trinidadian"},
//   {name: "Ingessana"},
//   {name: "Ingushe"},
//   {name: "Innu"},
//   {name: "Inuit"},
//   {name: "Irani"},
//   {name: "Iraqi Turkmen"},
//   {name: "Irish"},
//   {name: "Iroquoi"},
//   {name: "Ishkashmi"},
//   {name: "Isleta"},
//   {name: "Isoko"},
//   {name: "Istriot"},
//   {name: "Istro-Romanian"},
//   {name: "Italian"},
//   {name: "Itelmen"},
//   {name: "Itsekiri"},
//   {name: "Izhorian"},
//   {name: "Iñupiat"},
//   {name: "Jakaltek"},
//   {name: "Jakut"},
//   {name: "Janjevci"},
//   {name: "Japanese"},
//   {name: "Jassic"},
//   {name: "Javanese"},
//   {name: "Jebala"},
//   {name: "Jemez"},
//   {name: "Jing"},
//   {name: "Jingpo"},
//   {name: "Jino"},
//   {name: "Jivaro"},
//   {name: "Jola"},
//   {name: "Jopadhola"},
//   {name: "Jorcharia"},
//   {name: "Jri"},
//   {name: "Kabarday"},
//   {name: "Kalaallit"},
//   {name: "Kalash"},
//   {name: "Kale"},
//   {name: "Kalenjin"},
//   {name: "Kaliai"},
//   {name: "Kalispel"},
//   {name: "Kallawaya"},
//   {name: "Kalmyk"},
//   {name: "Kaluli"},
//   {name: "Kama"},
//   {name: "Kamayura"},
//   {name: "Kanembu"},
//   {name: "Kannadiga"},
//   {name: "Kapampangan"},
//   {name: "Kapauku"},
//   {name: "Karachay"},
//   {name: "Karaim"},
//   {name: "Karajá"},
//   {name: "Karakalpak"},
//   {name: "Karamojong"},
//   {name: "Karata"},
//   {name: "Karelian"},
//   {name: "Karen"},
//   {name: "Karok"},
//   {name: "Kashubian"},
//   {name: "Katang"},
//   {name: "Katuquina"},
//   {name: "Kavango"},
//   {name: "Kaw"},
//   {name: "Kayapo"},
//   {name: "Kazakh"},
//   {name: "Kenyah"},
//   {name: "Keresan"},
//   {name: "Ket"},
//   {name: "Khaka"},
//   {name: "Khang"},
//   {name: "Khant"},
//   {name: "Khasia"},
//   {name: "Khassonké"},
//   {name: "Khevi"},
//   {name: "Khevsureti"},
//   {name: "Khinalug"},
//   {name: "Khmer"},
//   {name: "Khmu"},
//   {name: "Kho Mu"},
//   {name: "Khoikhoi"},
//   {name: "Khomani or Nu"},
//   {name: "Khufi"},
//   {name: "Khvarchi"},
//   {name: "Kickapoo"},
//   {name: "Kinh"},
//   {name: "Kiowa"},
//   {name: "Klallam"},
//   {name: "Klamath"},
//   {name: "Klikitat"},
//   {name: "Knanaya"},
//   {name: "Kodava"},
//   {name: "Kogi"},
//   {name: "Kolchan"},
//   {name: "Kombai"},
//   {name: "Komi"},
//   {name: "Kongo"},
//   {name: "Koniag"},
//   {name: "Kootenai"},
//   {name: "Koptian"},
//   {name: "Korean"},
//   {name: "Korowai"},
//   {name: "Koryak"},
//   {name: "Koskimo"},
//   {name: "Kosraean"},
//   {name: "Koyukon"},
//   {name: "Kozak"},
//   {name: "Kpelle"},
//   {name: "Kraho"},
//   {name: "Krashovan"},
//   {name: "Kri"},
//   {name: "Kristang"},
//   {name: "Krymchak"},
//   {name: "Ktunaxa"},
//   {name: "Kuban Cossack"},
//   {name: "Kubu"},
//   {name: "Kuikuru"},
//   {name: "Kumeyaay"},
//   {name: "Kumyk"},
//   {name: "Kuna"},
//   {name: "Kurd"},
//   {name: "Kuruba Gowda"},
//   {name: "Kwakiutl"},
//   {name: "Kwakwaka'wakw"},
//   {name: "Kyrgyz"},
//   {name: "Kĩkũyũ"},
//   {name: "La Chi"},
//   {name: "La Ha"},
//   {name: "Laguna"},
//   {name: "Lahu"},
//   {name: "Laigain"},
//   {name: "Lakota"},
//   {name: "Lak"},
//   {name: "Lamet"},
//   {name: "Langi"},
//   {name: "Lao"},
//   {name: "Lao American"},
//   {name: "Lao Sung"},
//   {name: "Lao Theung"},
//   {name: "Latgalian"},
//   {name: "Latino Zamboangueño"},
//   {name: "Latvian"},
//   {name: "Lavae"},
//   {name: "Laven"},
//   {name: "Layap"},
//   {name: "Laz"},
//   {name: "Lazoi"},
//   {name: "Lebou"},
//   {name: "Lemko"},
//   {name: "Lenca"},
//   {name: "Lengua"},
//   {name: "Leonese"},
//   {name: "Lezgi"},
//   {name: "Lhoba"},
//   {name: "Lhotshampa"},
//   {name: "Li"},
//   {name: "Limburgish"},
//   {name: "Limbu"},
//   {name: "Lipka Tatar"},
//   {name: "Lipovan"},
//   {name: "Lisu"},
//   {name: "Lithuanian"},
//   {name: "Livonian"},
//   {name: "Lo Lo"},
//   {name: "Lobi"},
//   {name: "Lotuko"},
//   {name: "Louisiana Creole"},
//   {name: "Lozi"},
//   {name: "Lua"},
//   {name: "Luba"},
//   {name: "Lue"},
//   {name: "Luhya"},
//   {name: "Luiseno"},
//   {name: "Lumad"},
//   {name: "Lumbee"},
//   {name: "Lummi"},
//   {name: "Lun Bawang/Lun Dayeh"},
//   {name: "Lunda"},
//   {name: "Luo"},
//   {name: "Luxembourg American"},
//   {name: "Luxembourger"},
//   {name: "M'Baka"},
//   {name: "Maasai"},
//   {name: "Macaoese"},
//   {name: "Macedonian"},
//   {name: "Macuxi"},
//   {name: "Madeiran"},
//   {name: "Madheshi"},
//   {name: "Madurese"},
//   {name: "Magar"},
//   {name: "Mahican"},
//   {name: "Mahorian"},
//   {name: "Maidu"},
//   {name: "Mailu"},
//   {name: "Maingtha"},
//   {name: "Maka"},
//   {name: "Makah"},
//   {name: "Makong"},
//   {name: "Makua"},
//   {name: "Malagasi"},
//   {name: "Malay"},
//   {name: "Malayali"},
//   {name: "Maliseet"},
//   {name: "Maltese"},
//   {name: "Mam"},
//   {name: "Mamamwa"},
//   {name: "Manasi"},
//   {name: "Manchu"},
//   {name: "Mandan"},
//   {name: "Mandinka"},
//   {name: "Mang"},
//   {name: "Mangbetu"},
//   {name: "Mangyan"},
//   {name: "Mansi"},
//   {name: "Manx"},
//   {name: "Maonan"},
//   {name: "Mapuche"},
//   {name: "Marathi"},
//   {name: "Mari"},
//   {name: "Maricopa"},
//   {name: "Marind-Anim"},
//   {name: "Marwari"},
//   {name: "Mashantucket Pequot"},
//   {name: "Matabele"},
//   {name: "Mataco"},
//   {name: "Mati"},
//   {name: "Mattaponi"},
//   {name: "Maubere"},
//   {name: "Maya"},
//   {name: "Mayo"},
//   {name: "Mazandareni"},
//   {name: "Mbaya"},
//   {name: "Mbochi"},
//   {name: "Mbuti"},
//   {name: "Mbuti"},
//   {name: "Me-Wuk"},
//   {name: "Megleno-Romanian"},
//   {name: "Meherrin"},
//   {name: "Meitei"},
//   {name: "Mekeo"},
//   {name: "Mekeo"},
//   {name: "Melungeon"},
//   {name: "Memon"},
//   {name: "Menba"},
//   {name: "Mende"},
//   {name: "Mennonite"},
//   {name: "Menominee"},
//   {name: "Mentawai"},
//   {name: "Mer"},
//   {name: "Meskhetian"},
//   {name: "Mestizo"},
//   {name: "Mewat"},
//   {name: "Mi'kmaq"},
//   {name: "Miao"},
//   {name: "Miccosukee"},
//   {name: "Mina"},
//   {name: "Minahasa/Manadonese"},
//   {name: "Minangkabau"},
//   {name: "Mingo"},
//   {name: "Mingrelian"},
//   {name: "Miskito"},
//   {name: "Mission"},
//   {name: "Mitsogo"},
//   {name: "Miwok"},
//   {name: "Mixtec"},
//   {name: "Mizo"},
//   {name: "Mizrahi Jew"},
//   {name: "Mlabri"},
//   {name: "Mnong"},
//   {name: "Modoc"},
//   {name: "Mohajir"},
//   {name: "Mohave"},
//   {name: "Mohawk"},
//   {name: "Mohegan"},
//   {name: "Moksha"},
//   {name: "Moldovan"},
//   {name: "Molise Croat"},
//   {name: "Mon"},
//   {name: "Monacan"},
//   {name: "Monesque"},
//   {name: "Mongo"},
//   {name: "Mongol"},
//   {name: "Mono"},
//   {name: "Monovish"},
//   {name: "Montagnai"},
//   {name: "Montaukett"},
//   {name: "Montenegrin"},
//   {name: "Moravian"},
//   {name: "Moriori"},
//   {name: "Morisco"},
//   {name: "Morlach"},
//   {name: "Moro"},
//   {name: "Mossi"},
//   {name: "Motuan"},
//   {name: "Muckleshoot"},
//   {name: "Mudéjar"},
//   {name: "Muhajir"},
//   {name: "Mulam"},
//   {name: "Mulatto"},
//   {name: "Munda"},
//   {name: "Mundurucu"},
//   {name: "Muong"},
//   {name: "Mursi"},
//   {name: "Museu"},
//   {name: "Myene"},
//   {name: "Méti"},
//   {name: "Māori"},
//   {name: "N/u or Khomani"},
//   {name: "Naga"},
//   {name: "Nahanni"},
//   {name: "Nahua"},
//   {name: "Namaqua"},
//   {name: "Nanai"},
//   {name: "Nansemond"},
//   {name: "Narragansett"},
//   {name: "Nasia"},
//   {name: "Natchez"},
//   {name: "Nauruan"},
//   {name: "Navajo"},
//   {name: "Naxi"},
//   {name: "Ndau"},
//   {name: "Ndebele"},
//   {name: "Negidal"},
//   {name: "Negrito"},
//   {name: "Nenet"},
//   {name: "Nespelem"},
//   {name: "Nevisian"},
//   {name: "Newar"},
//   {name: "Nez Percé"},
//   {name: "Ngae"},
//   {name: "Nganasan"},
//   {name: "Ngasan"},
//   {name: "Ngái"},
//   {name: "Nhahuen"},
//   {name: "Nhuon"},
//   {name: "Ni-Vanuatu"},
//   {name: "Niominka"},
//   {name: "Nipmuc"},
//   {name: "Nisei"},
//   {name: "Nishka"},
//   {name: "Nisqually"},
//   {name: "Nisse"},
//   {name: "Niuean – Polynesian"},
//   {name: "Nivkh"},
//   {name: "Njem"},
//   {name: "Nogai"},
//   {name: "Nomlaki"},
//   {name: "Nooksack"},
//   {name: "Northumbrian"},
//   {name: "Norwegian"},
//   {name: "Nu"},
//   {name: "Nuba"},
//   {name: "Nubian"},
//   {name: "Nuer"},
//   {name: "Nukak"},
//   {name: "Nung"},
//   {name: "Nuristani"},
//   {name: "Nuu-chah-nulth"},
//   {name: "Nyagatom"},
//   {name: "Nzema"},
//   {name: "O Du"},
//   {name: "O'Odham"},
//   {name: "Occitania"},
//   {name: "Odawa"},
//   {name: "Ogaden"},
//   {name: "Oglala"},
//   {name: "Ogoni"},
//   {name: "Ojibwa"},
//   {name: "Okamba"},
//   {name: "Okande"},
//   {name: "Okie"},
//   {name: "Okinawan"},
//   {name: "Omagua"},
//   {name: "Omaha"},
//   {name: "Oneida"},
//   {name: "Onondaga"},
//   {name: "Oroch"},
//   {name: "Orokaiva"},
//   {name: "Orok"},
//   {name: "Oromo"},
//   {name: "Oroqen"},
//   {name: "Oroshori"},
//   {name: "Osage Nation"},
//   {name: "Ossetian"},
//   {name: "Otavaleño"},
//   {name: "Otoe-Missouria"},
//   {name: "Ottawa"},
//   {name: "Ovambo"},
//   {name: "Pa Then"},
//   {name: "Pa-O"},
//   {name: "Pa-Thi"},
//   {name: "Pacoh"},
//   {name: "Paiute"},
//   {name: "Palcene"},
//   {name: "Paliyan"},
//   {name: "Pamunkey"},
//   {name: "Panamanian"},
//   {name: "Pangasinan"},
//   {name: "Panoan"},
//   {name: "Papel"},
//   {name: "Parsi"},
//   {name: "Pashtun"},
//   {name: "Pashu"},
//   {name: "Passamaquoddy"},
//   {name: "Pataxo"},
//   {name: "Pattar"},
//   {name: "Paugusset"},
//   {name: "Pawnee"},
//   {name: "Penan"},
//   {name: "Pennsylvania Dutch"},
//   {name: "Pennsylvania German"},
//   {name: "Penobscot"},
//   {name: "Peoria"},
//   {name: "Perce"},
//   {name: "Persian"},
//   {name: "Phong"},
//   {name: "Phu La"},
//   {name: "Phu Noi"},
//   {name: "Phu Thai"},
//   {name: "Picardy"},
//   {name: "Pied-noir"},
//   {name: "Piegan"},
//   {name: "Pilaga"},
//   {name: "Pima"},
//   {name: "Pit River Indian"},
//   {name: "Pitcairn-Norfolk"},
//   {name: "Pokot"},
//   {name: "Polabian Slav"},
//   {name: "Polish"},
//   {name: "Polynesian"},
//   {name: "Pomak"},
//   {name: "Pomo"},
//   {name: "Ponca"},
//   {name: "Ponhpeian"},
//   {name: "Pontic Greek"},
//   {name: "Poospatuck"},
//   {name: "Portuguese"},
//   {name: "Potawatomi"},
//   {name: "Potiguara"},
//   {name: "Powhatan"},
//   {name: "Pu Peo"},
//   {name: "Pueblo"},
//   {name: "Puelche"},
//   {name: "Puerto Rican"},
//   {name: "Puget Sound Salish"},
//   {name: "Pumi"},
//   {name: "Punan"},
//   {name: "Punjabi"},
//   {name: "Purépecha"},
//   {name: "Puyallup"},
//   {name: "Pākehā"},
//   {name: "Q'ero"},
//   {name: "Qashqai"},
//   {name: "Qiang"},
//   {name: "Quahatika"},
//   {name: "Quapaw"},
//   {name: "Quechan"},
//   {name: "Quechua"},
//   {name: "Quiché"},
//   {name: "Quileute"},
//   {name: "Quinault"},
//   {name: "Quinqui"},
//   {name: "Québécoi"},
//   {name: "Ra Glai"},
//   {name: "Rai"},
//   {name: "Rajasthani"},
//   {name: "Rakhine"},
//   {name: "Rakuba"},
//   {name: "Ramapough Mountain Indian"},
//   {name: "Rappahannock"},
//   {name: "Rashaida"},
//   {name: "Rehoboth Baster"},
//   {name: "Ripuarian"},
//   {name: "Ro Mam"},
//   {name: "Rohingya"},
//   {name: "Romani"},
//   {name: "Romanian"},
//   {name: "Roshani"},
//   {name: "Rotuman"},
//   {name: "Russian"},
//   {name: "Rusyn"},
//   {name: "Ruthenian"},
//   {name: "Rutul"},
//   {name: "Ryukyuan"},
//   {name: "S'Klallam"},
//   {name: "Saarlander"},
//   {name: "Sadang"},
//   {name: "Saek"},
//   {name: "Saho"},
//   {name: "Saingolo"},
//   {name: "Salar"},
//   {name: "Salish"},
//   {name: "Samanthan"},
//   {name: "Samaritan"},
//   {name: "Samburu"},
//   {name: "Samegrelo"},
//   {name: "Sami"},
//   {name: "Sammarinese"},
//   {name: "Samoan"},
//   {name: "Samogitian"},
//   {name: "Samojeed"},
//   {name: "Samtao"},
//   {name: "San"},
//   {name: "San Chay"},
//   {name: "San Diu"},
//   {name: "Sanema"},
//   {name: "Santal"},
//   {name: "Santee Sioux"},
//   {name: "Saponi"},
//   {name: "Sara"},
//   {name: "Saraiki"},
//   {name: "Sarakatsani"},
//   {name: "Saramaka"},
//   {name: "Sauk"},
//   {name: "Sauk-Suiattle"},
//   {name: "Savoyard"},
//   {name: "Scottish"},
//   {name: "Sekani"},
//   {name: "Selk'nam"},
//   {name: "Selkup"},
//   {name: "Semai"},
//   {name: "Seminole"},
//   {name: "Sena"},
//   {name: "Seneca"},
//   {name: "Senegalese"},
//   {name: "Sentinelese"},
//   {name: "Sephardic Jew"},
//   {name: "Serb"},
//   {name: "Serer"},
//   {name: "Serer-Ndut"},
//   {name: "Seychellois Creole"},
//   {name: "Seychelloi"},
//   {name: "Shan"},
//   {name: "Shangaan"},
//   {name: "Shasta"},
//   {name: "Shavante"},
//   {name: "Shawnee"},
//   {name: "She"},
//   {name: "Sherpa"},
//   {name: "Shinnecock"},
//   {name: "Shipibo"},
//   {name: "Shoalwater Bay Tribe"},
//   {name: "Shona"},
//   {name: "Shor"},
//   {name: "Shoshone"},
//   {name: "Shughni"},
//   {name: "Shui"},
//   {name: "Si La"},
//   {name: "Sidamo"},
//   {name: "Siddi"},
//   {name: "Siksika"},
//   {name: "Silesian"},
//   {name: "Siletz"},
//   {name: "Sindhi"},
//   {name: "Singmun"},
//   {name: "Sinhalese"},
//   {name: "Sinti"},
//   {name: "Sioux"},
//   {name: "Siuslaw"},
//   {name: "Skagit"},
//   {name: "Skokomish"},
//   {name: "Slav"},
//   {name: "Slovak"},
//   {name: "Slovene"},
//   {name: "Sokci"},
//   {name: "Somali"},
//   {name: "Somba"},
//   {name: "Songhai"},
//   {name: "Soninke"},
//   {name: "Sorb"},
//   {name: "Souei"},
//   {name: "Southern Tutchone"},
//   {name: "Spaniard"},
//   {name: "Spokane"},
//   {name: "Squaxin Island Tribe"},
//   {name: "Sri Lankan Moor"},
//   {name: "Stillaguamish"},
//   {name: "Sundanese"},
//   {name: "Suquamish"},
//   {name: "Suri"},
//   {name: "Surui"},
//   {name: "Susu"},
//   {name: "Suyá"},
//   {name: "Svan"},
//   {name: "Swahili"},
//   {name: "Swazi"},
//   {name: "Swede"},
//   {name: "Swinomish"},
//   {name: "Swiss Romansh"},
//   {name: "Sylheti"},
//   {name: "Syrian Christian"},
//   {name: "Sḵwxwú7mesh"},
//   {name: "T'boli"},
//   {name: "Ta Oi"},
//   {name: "Tabasaran"},
//   {name: "Tachi"},
//   {name: "Taché"},
//   {name: "Tagalog"},
//   {name: "Tagish"},
//   {name: "Taiwanese"},
//   {name: "Tajik"},
//   {name: "Taliang"},
//   {name: "Talysh"},
//   {name: "Tamang"},
//   {name: "Tamil"},
//   {name: "Tanana"},
//   {name: "Tanna"},
//   {name: "Taos Pueblo Indian"},
//   {name: "Tapajo"},
//   {name: "Tapirapé"},
//   {name: "Tapuia"},
//   {name: "Tarahumara"},
//   {name: "Tarascan"},
//   {name: "Tasaday"},
//   {name: "Tatar"},
//   {name: "Tat"},
//   {name: "Tay"},
//   {name: "Taíno"},
//   {name: "Teda"},
//   {name: "Tehuelche"},
//   {name: "Teimani Jewish Jew"},
//   {name: "Tejano"},
//   {name: "Telefolmin"},
//   {name: "Telugu"},
//   {name: "Terena"},
//   {name: "Teton"},
//   {name: "Tewa"},
//   {name: "Thai"},
//   {name: "Thakali"},
//   {name: "Tharu"},
//   {name: "Thin"},
//   {name: "Tibetan"},
//   {name: "Ticuna"},
//   {name: "Tigray-Tigrinya"},
//   {name: "Tigre"},
//   {name: "Tigua"},
//   {name: "Tindi"},
//   {name: "Tipra"},
//   {name: "Tlakluit"},
//   {name: "Tlingit"},
//   {name: "Toala"},
//   {name: "Toba"},
//   {name: "Tofalar"},
//   {name: "Tohono O'odham"},
//   {name: "Tokelauan"},
//   {name: "Tolai"},
//   {name: "Tolowa"},
//   {name: "Toltec"},
//   {name: "Tonga"},
//   {name: "Tongan"},
//   {name: "Tongva"},
//   {name: "Tonkawa"},
//   {name: "Topachula"},
//   {name: "Toraja"},
//   {name: "Torbesh"},
//   {name: "Torres Strait Islander"},
//   {name: "Totonac"},
//   {name: "Toubou"},
//   {name: "Transylvanian Saxon"},
//   {name: "Tsakhur"},
//   {name: "Tsetsaut"},
//   {name: "Tsez"},
//   {name: "Tsimishian"},
//   {name: "Tsonga"},
//   {name: "Tsuu T'ina"},
//   {name: "Tswana"},
//   {name: "Tuamotu"},
//   {name: "Tuareg"},
//   {name: "Tujia"},
//   {name: "Tukano"},
//   {name: "Tukolor"},
//   {name: "Tulalip"},
//   {name: "Tulutni"},
//   {name: "Tum"},
//   {name: "Tumbuka"},
//   {name: "Tunica-Biloxi"},
//   {name: "Tupian"},
//   {name: "Tupinamba"},
//   {name: "Tupí"},
//   {name: "Turkish Cypriot"},
//   {name: "Turkmen"},
//   {name: "Turk"},
//   {name: "Tusheti"},
//   {name: "Tutsi"},
//   {name: "Tuvaluan"},
//   {name: "Tuvan"},
//   {name: "Twa"},
//   {name: "Txicao"},
//   {name: "Tzigane"},
//   {name: "U'wa"},
//   {name: "Udege"},
//   {name: "Udi"},
//   {name: "Ukrainian"},
//   {name: "Ulch"},
//   {name: "Ulster-Scot"},
//   {name: "Ulta"},
//   {name: "Umatilla"},
//   {name: "Umpqua"},
//   {name: "Upper Skagit"},
//   {name: "Urapmin"},
//   {name: "Uruguayan"},
//   {name: "Ute"},
//   {name: "Uyghur"},
//   {name: "Uzbek"},
//   {name: "Vaccaei"},
//   {name: "Valencian"},
//   {name: "Vaturanga"},
//   {name: "Venda"},
//   {name: "Venesuala"},
//   {name: "Vep"},
//   {name: "Visayan"},
//   {name: "Vlach"},
//   {name: "Volga German"},
//   {name: "Vote"},
//   {name: "Wa"},
//   {name: "Wabanaki"},
//   {name: "Waccamaw"},
//   {name: "Wailaki"},
//   {name: "Waitaha"},
//   {name: "Waiwai"},
//   {name: "Wakh"},
//   {name: "Waki"},
//   {name: "Walla Walla"},
//   {name: "Walloon"},
//   {name: "Walser"},
//   {name: "Wampanoag"},
//   {name: "Wasco"},
//   {name: "Washoe"},
//   {name: "Wayana"},
//   {name: "Welayta"},
//   {name: "Welsh"},
//   {name: "West Fleming"},
//   {name: "White Mountain Apache"},
//   {name: "Wichita"},
//   {name: "Wintu"},
//   {name: "Wintun"},
//   {name: "Wishram"},
//   {name: "Wiyot"},
//   {name: "Wolof"},
//   {name: "Wu Chinese"},
//   {name: "Wyandot"},
//   {name: "Wyyanaha"},
//   {name: "Xakriabá"},
//   {name: "Xavante"},
//   {name: "Xerente"},
//   {name: "Xhosa"},
//   {name: "Xibe"},
//   {name: "Xikrin"},
//   {name: "Xin Uygur"},
//   {name: "Xinh Mun"},
//   {name: "Xo Dang"},
//   {name: "Xtieng"},
//   {name: "Xucuru"},
//   {name: "Xueda"},
//   {name: "Yaghan"},
//   {name: "Yaghnabi"},
//   {name: "Yagua"},
//   {name: "Yakama"},
//   {name: "Yakugir"},
//   {name: "Yakut"},
//   {name: "Yang"},
//   {name: "Yankton Sioux"},
//   {name: "Yanomami"},
//   {name: "Yao"},
//   {name: "Yapese"},
//   {name: "Yaqui"},
//   {name: "Yavapai"},
//   {name: "Yawalpiti"},
//   {name: "Yawanawa"},
//   {name: "Yazgulami"},
//   {name: "Yekuana"},
//   {name: "Yi"},
//   {name: "Yocha-Dehe"},
//   {name: "Yokut"},
//   {name: "Yoruba"},
//   {name: "Yu"},
//   {name: "Yuchi"},
//   {name: "Yugur"},
//   {name: "Yukaghir"},
//   {name: "Yuki"},
//   {name: "Yuma"},
//   {name: "Yumbri"},
//   {name: "Yupik"},
//   {name: "Yurok"},
//   {name: "Yörük"},
//   {name: "Zaghawa"},
//   {name: "Zambo"},
//   {name: "Zapotec"},
//   {name: "Zarma"},
//   {name: "Zaza"},
//   {name: "Zhuang"},
//   {name: "Zou"},
//   {name: "Zulian"},
//   {name: "Zulu"},
//   {name: "Zuni"},
// ];

export default authors;