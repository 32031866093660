import React from 'react';
import useScrollBlock from '../Hooks/useScrollBlock'

const SearchButton = (props) => {
  const blockScroll = useScrollBlock()[0];

  const handleClick = (e) => {
    props.onClick(e)
    blockScroll()
  }

  return (
    <span onClick={handleClick} className={`button ${props.searchClass} is-rounded`}>{props.title}</span>
  );
};

export default SearchButton;
