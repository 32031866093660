import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = (props) => {
  const fixedBottom = props.fixedBottom ? "is-fixed-bottom" : "navbar-bottom"
  return (
    <div>
    <nav className={`no-mobile navbar ${fixedBottom} transparent primary-text-color' role='navigation' aria-label='main navigation`}>
        <div className='navbar-start navbar-item primary-text-color'>
          <p className="primary-font-heavy ml-4">Storie</p><p className="primary-font-light">&nbsp;is a platform to find and support diverse storytellers.</p>
        </div>
        <div className='navbar-end primary-font-heavy'>
            <div className='buttons is-hidden-touch m-4'>
              <NavLink to='/terms' className='navbar-item primary-text-color ml-4 mr-4'>Terms</NavLink>
              <NavLink to='/privacy' className='navbar-item primary-text-color mr-4'>Privacy</NavLink>
            </div>
        </div>
    </nav>
    <nav className={`small-desktop navbar ${fixedBottom} transparent primary-text-color`} role='navigation' aria-label='main navigation'  >

        <div className='navbar-end primary-font-heavy'>
            <div className='buttons centered m-1' style={{width: "100vw"}}>
              <NavLink to='/terms' className='navbar-item primary-text-color ml-4 mr-4'>Terms</NavLink>
              <NavLink to='/privacy' className='navbar-item primary-text-color mr-4'>Privacy</NavLink>
            </div>
        </div>
    </nav>
    </div>
  );
}

export default Footer;