import React, { Fragment } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';
import authors from '../Data/authors';
import 'react-bootstrap-typeahead/css/Typeahead.css';

class TypeaheadAuthor extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(e) {
    this.props.onAuthorChange(e);
  }

  render() {
    // .filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)
    const selected = this.props.authors
    const className = this.props.matchAny ? 'typeahead-author-any' : 'typeahead-author'
    return (
      <Fragment>
        <Form.Group style={{ marginTop: '20px' }}>
          <Typeahead
            allowNew
            id="basic-typeahead-multiple author"
            labelKey="name"
            multiple
            onChange={this.handleChange}
            options={authors}
            placeholder="Choose author..."
            selected={selected}
            className={className}
            newSelectionPrefix="Add a new filter: "
            ignoreDiacritics={true}
          />
        </Form.Group>
      </Fragment>
    );
  }
};

export default TypeaheadAuthor;
