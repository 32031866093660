import React, { Fragment, useState, useRef } from 'react'
import ResultCard from './ResultCard'
import ResultDetails from './ResultDetails'
import Loader from './Loader'

import { useInfiniteQuery } from 'react-query'
import useIntersectionObserver from '../Hooks/useIntersectionObserver'
import useScrollBlock from '../Hooks/useScrollBlock'
import doubleCaretLeft from '../Assets/double-caret-left.svg';
import doubleCaretRight from '../Assets/double-caret-right.svg';
import escapeQueryTerm from '../Utils/query'

const NUM_RESULTS_PER_PAGE = 15;
const REACT_APP_BOOK_DB_URL = process.env.REACT_APP_BOOK_DB_URL;
const elasticsearch         = require('elasticsearch');
const client                = new elasticsearch.Client({host: REACT_APP_BOOK_DB_URL});



const Results = (props) => {
  const searchParams = props.searchParams;
  const genres = searchParams.genres.map(genre => genre.name)
  const authors = searchParams.authors.filter(author => author.name && !author.customOption).map(author => author.name)
  const authorNames = searchParams.authors.filter(author => author.name && author.customOption).map(author => author.name)
  const keywords = searchParams.keywords.map(keyword => keyword.name)

  const matchAnyGenre = searchParams.matchAnyGenre
  const matchAnyAuthor = searchParams.matchAnyAuthor
  const matchAnyKeyword = searchParams.matchAnyKeyword

  const allowScroll = useScrollBlock()[1];
  let [numResults, updateNumResults] = useState(0);
  let [resultsShown, updateResultsShown] = useState([]);
  let [selectedResult, updateSelectedResult] = useState()

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['books', genres, authors, keywords],
    async ({ pageParam = 0 }) => {
      const body = {query: {bool: {must: []}}}

      if (genres.length > 0) {
        const searchGenres = genres.map((genre) => { return {"match_phrase": {"genres": escapeQueryTerm(genre)}} })
        const queryGenres = matchAnyGenre ? {bool: {should: searchGenres}} : {bool: {must: searchGenres}}
        body.query.bool.must.push(queryGenres)
      }
      if (authors.length > 0) {
        const searchAuthors = authors.map((author) => { return {"match_phrase": {"author_identifiers": escapeQueryTerm(author)}} })
        const queryAuthors = matchAnyAuthor ? {bool: {should: searchAuthors}} : {bool: {must: searchAuthors}}
        body.query.bool.must.push(queryAuthors)
      }
      if (authorNames.length > 0) {
        const searchAuthorNames = authorNames.map((authorName) => { return {"match": {"author": escapeQueryTerm(authorName)}} })
        const queryAuthorNames = matchAnyAuthor ? {bool: {should: searchAuthorNames}} : {bool: {must: searchAuthorNames}}
        body.query.bool.must.push(queryAuthorNames)
      }
      if (keywords.length > 0) {
        const searchKeywords = keywords.map((keyword) => { return {"match": {"description": escapeQueryTerm(keyword)}} })
        const queryKeywords = matchAnyKeyword ? {bool: {should: searchKeywords}} : {bool: {must: searchKeywords}}
        body.query.bool.must.push(queryKeywords)
      }
      
      const response = await client.search({
        index: 'storie-production',
        type: 'book',
        from: pageParam,
        size: NUM_RESULTS_PER_PAGE,
        body: body
      })

      updateNumResults(response.hits.total.value);
      const newResults = [];
      response.hits.hits.forEach((result, i) => {
        result._source.searchIndex = i + (pageParam / NUM_RESULTS_PER_PAGE) * NUM_RESULTS_PER_PAGE;
        if (!(result._source in resultsShown)) {
          newResults.push(result._source)
        }
      })

      if (pageParam === 0) {
        updateResultsShown(newResults);
      } else {
        updateResultsShown([ ...resultsShown, ...newResults ]);
      }
      return response.hits
    },
    {
      getNextPageParam: lastPage => {
        if (numResults === resultsShown.length) {
          return undefined;
        }
        return resultsShown.length;
      },
      refetchOnWindowFocus: false
    }
  )

  const loadMoreButtonRef = useRef()
  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  })

  const onResultsCardClick = (e, clickedCard, direction="") => {
    allowScroll()
    let pageHeight = window.outerHeight;
    window.scrollBy(0, pageHeight)

    let previous;
    let next;
    const currentIndex = clickedCard ? clickedCard.searchIndex : 0;
    
    if (direction === 'next') {
      next = currentIndex < (resultsShown.length - 1) ? resultsShown[currentIndex + 1] : null;
      updateSelectedResult(next)
    } else if (direction === 'prev') {
      previous = currentIndex > 0 ? resultsShown[currentIndex - 1] : null;
      updateSelectedResult(previous)
    } else {
      updateSelectedResult(clickedCard)
    }
  }

  return (
    <div>
      <div className="results-count primary-font-light">{status === 'loading' ? null : numResults + ' books'}</div>
      <div className="results-gallery">
        <div className="results">
          {status === 'loading' ? <Loader/> : status === 'error' ? (<span className="error-message">Error: {error.message}</span>) : (
            <>
              {data.pages.map((page, j) => (
                <Fragment key={'page-'+j || 'no-next-page'}>
                  {page.hits.map((result, i) => {
                    result._source.searchIndex = i + j * NUM_RESULTS_PER_PAGE;
                    return <ResultCard value={result._source} key={'result-' + i} onClick={((e) => onResultsCardClick(e, result._source, ''))}/>
                  })}
                </Fragment>
              ))}
            </>
          )}
          <div className="load-more" ref={loadMoreButtonRef}>
            <span>
              {isFetchingNextPage ? 
                <Loader className="centered"/> : 
                (hasNextPage || status === 'loading') ? null : 
                (numResults > 5) ? <div className='end-results'><div className="primary-font-heavy">End of Results</div><div className="primary-font-light">{numResults + ' books shown'}</div></div> :
                (numResults === 0)  ? <div><p className="no-results primary-font-heavy" style={{fontSize: '20px'}}>No results found</p></div> : null
              }       
            </span>   
          </div>
        </div>
      </div>
      {
        !selectedResult ? 
        null :
        <>
        {(selectedResult ? selectedResult.searchIndex : 0) > 0 ? <span className='previous-result primary-font-light' onClick={((e) => onResultsCardClick(e, selectedResult, 'prev'))}><img src={doubleCaretLeft} width="20" alt=""/></span> : null}
        {(selectedResult ? selectedResult.searchIndex : 0) < (resultsShown.length - 1) ? <span className='next-result primary-font-light' onClick={((e) => onResultsCardClick(e, selectedResult, 'next'))}><img src={doubleCaretRight} width="20" alt=""/></span> : null}
        <ResultDetails className='result-details' searchParams={searchParams} selectedResult={selectedResult} key='result-details'/>
        </>
      }

    </div>
  )
}

export default Results;
