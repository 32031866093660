import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../logo.svg'

function toggleNavbarBurger() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('#navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach( el => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
    }
}

const Header = (props) => {
  const headerClassName = props.fixed ? 'navbar is-fixed-top transparent primary-text-color' : 'navbar transparent primary-text-color'
  return (
    <nav className={headerClassName} role='navigation' aria-label='main navigation'>
      <NavLink to='/' className='navbar-left is-hidden-desktop m-3'><img src={logo} width="30" height="30" alt=""/></NavLink>
      <NavLink to='/' className='no-hover navbar-center is-hidden-desktop secondary-font primary-text-color is-size-5 m-3'><div className="mr-4">Storie</div></NavLink>

      <div className='navbar-brand'>
        <NavLink to='/' className="is-hidden-touch ml-6 m-3" ><img src={logo} width="50" height="50" alt=""/></NavLink>
        <NavLink to='/' className='no-hover navbar-item secondary-font is-hidden-touch  primary-text-color is-size-3 ml-4'><div>Storie</div></NavLink>
      
        <div
          role='button'
          className='navbar-burger primary-text-color'
          aria-label='menu'
          aria-expanded='false'
          data-target='navbar-menu'
          id='navbar-burger'
          onClick={toggleNavbarBurger}
        >
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </div>
      </div>

      <div id='navbar-menu' className='navbar-menu'>

        <div className='navbar-end primary-font-heavy'>
            <div className='buttons is-hidden-touch'>
              <NavLink to='/search' className='navbar-item primary-text-color m-0'>Search</NavLink>
              <NavLink to='/resources' className='navbar-item primary-text-color m-4'>Resources</NavLink>
              <NavLink to='/about' className='navbar-item primary-text-color  ml-0 mr-5'>About Us</NavLink>
              <a href="https://www.buymeacoffee.com/storie" target='_blank' rel="noreferrer" className='button is-primary is-rounded m-0 mr-6'>Buy us a coffee</a>
            </div>

            <div className='buttons-mobile centered is-hidden-desktop mb-3 ml-3 mr-3'>
              <NavLink to='/search' className='navbar-item primary-text-color'>Search</NavLink>
              <NavLink to='/resources' className='navbar-item primary-text-color'>Resources</NavLink>
              <NavLink to='/about' className='navbar-item primary-text-color'>About Us</NavLink>              
              <NavLink to='/resources' className='navbar-item primary-text-color'>Resources</NavLink>
              <NavLink to='/terms' className='navbar-item primary-text-color'>Terms</NavLink>
              <NavLink to='/privacy' className='navbar-item primary-text-color'>Privacy</NavLink>
              <a href="https://www.buymeacoffee.com/storie" target='_blank' rel="noreferrer" className='button is-primary is-rounded'>Buy us a coffee</a>
            </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;