import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import SearchModal from '../../Components/SearchModal';
import useScrollBlock from '../../Hooks/useScrollBlock'

const Search = () => {
  const blockScroll = useScrollBlock()[0];
  blockScroll()
  return (
    <>
      <div className="App">
        <Header />
        <div className="search-page">
          <div className="search-bg"/>
          <div className="result-details-bg"/>
          <header className="search-header">
            <SearchModal />
          </header>
        </div>
        <Footer fixedBottom={true}/>
      </div>
    </>
  );
}

export default Search;
