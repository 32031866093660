import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import ResourceTable from '../../Components/ResourceTable';
import useScrollBlock from '../../Hooks/useScrollBlock'

const Resources = () => {
  const allowScroll = useScrollBlock()[1];
  allowScroll()
  const [data, setData] = useState([]);

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    (async () => {
      const result = await axios("/api/resources.json");
      setData(result.data);
    })();
  }, []);

  // Custom component to render Genres 
	const Genres = ({ values }) => {
	  // Loop through the array and create a badge-like component instead of a comma-separated string
	  return (
	    <>
	      {values.map((genre, idx) => {
	        return (
	          <span key={idx} className="resource-genre">
	            {`${genre} `}
	          </span>
	        );
	      })}
	    </>
	  );
	};

  // Custom component to render authors 
	const Authors = ({ values }) => {
	  // Loop through the array and create a badge-like component instead of a comma-separated string
	  return (
	    <>
	      {values.map((author, idx) => {
	        return (
	          <p key={idx} className="resource-author">
	            {`${author} `}
	          </p>
	        );
	      })}
	    </>
	  );
	};

  // Custom component to render authors 
  const Site = ({ values }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <>
        <a href={values.url} target='_blank' rel='noreferrer' className='link'>{values.name}</a>
      </>
    );
  };

  // Custom component to render authors 
  const Social = ({ values }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <>
        {Object.keys(values).map((socialKey, idx) => {
          const socialUrl = values[socialKey];
          let socialSvg;
          switch (socialKey) {
            case 'facebook':
              socialSvg = <svg className={`m-2`} viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="23" height="23"><path d="M0 7.5a7.5 7.5 0 118 7.484V9h2V8H8V6.5A1.5 1.5 0 019.5 5h.5V4h-.5A2.5 2.5 0 007 6.5V8H5v1h2v5.984A7.5 7.5 0 010 7.5z" fill="currentColor"></path></svg>;
              break;
            case 'instagram':
              socialSvg = <svg className={`m-2`} viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="23" height="23"><radialGradient id="rg" r="150%" cx="30%" cy="107%"><stop stopColor="#fdf497" offset="0" /><stop stopColor="#fdf497" offset="0.05" /><stop stopColor="#fd5949" offset="0.45" /><stop stopColor="#d6249f" offset="0.6" /><stop stopColor="#285AEB" offset="0.9" /></radialGradient><path d="M7.5 5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M4.5 0A4.5 4.5 0 000 4.5v6A4.5 4.5 0 004.5 15h6a4.5 4.5 0 004.5-4.5v-6A4.5 4.5 0 0010.5 0h-6zM4 7.5a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zM11 4h1V3h-1v1z" fill="currentColor"></path></svg>
              break;
            case 'twitter':
              socialSvg = <svg className={`m-2`} viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="23" height="23"><path d="M14.977 1.467a.5.5 0 00-.87-.301 2.559 2.559 0 01-1.226.763A3.441 3.441 0 0010.526 1a3.539 3.539 0 00-3.537 3.541v.44C3.998 4.75 2.4 2.477 1.967 1.325a.5.5 0 00-.916-.048C.004 3.373-.157 5.407.604 7.139 1.27 8.656 2.61 9.864 4.51 10.665 3.647 11.276 2.194 12 .5 12a.5.5 0 00-.278.916C1.847 14 3.55 14 5.132 14h.048c4.861 0 8.8-3.946 8.8-8.812v-.479c.363-.37.646-.747.82-1.236.193-.546.232-1.178.177-2.006z" fill="currentColor"></path></svg>;
              break;
            default:
              socialSvg = <></>;
          }

          return (
            <a href={socialUrl} target='_blank' rel='noreferrer' key={idx} className={`resource-social ${socialKey}`}>
              {socialSvg}
            </a>
          );
        })}
      </>
    );
  };

	const columns = useMemo(
    () => [
      {
        Header: "Resource",
        accessor: "resource.site",
        Cell: ({ cell: { value } }) => <Site values={value} />
      },
      {
        Header: "Authors",
        accessor: "resource.authors",
        Cell: ({ cell: { value } }) => <Authors values={value} />,
        style: { 'whiteSpace': 'unset' } 
      },
      {
        Header: "Genres",
        accessor: "resource.genres",
        Cell: ({ cell: { value } }) => <Genres values={value} />,
        style: { 'whiteSpace': 'unset' } 
      },
      {
        Header: "Type",
        accessor: "resource.type"
      },
      {
        Header: "Language",
        accessor: "resource.language"
      },
      {
        Header: "Social",
        accessor: "resource.social",
        Cell: ({ cell: { value } }) => <Social values={value} />,
      }
    ],
    []
  );

  return (
    <>
      <div className="App" style={{height: '100vh'}}>
        <Header fixed={false}/>
        <div className="resource-bg"/>
        <ResourceTable columns={columns} data={data} />
        <Footer fixedBottom={false}/>
      </div>
    </>
  );
}

export default Resources;
