import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';

const Landing = () => {
  return (
    <>
      <div className="App">
        <Header fixed={true}/>
        <div className="middle-bg"/>
        <div className="middle-scroll">
        	<p className="secondary-font title">Terms & Conditions</p>
        	<br className='no-desktop'/>
        	<br/>
    		<div className="tldr primary-font-heavy">
    			<p className="subtitle">TL;DR</p>
    			<div className="bullet-list"> 
        			<div className="bullet">
	        			<ul>
	        				<li>We are not selling your data.</li>
	        			</ul>
        			</div>
        			<div className="bullet">
	        			<ul>
							<li>We are only analyzing search results to help us finetune what we should suggest as author, genre, and keyword terms on our search page.</li>
						</ul>
					</div>
				</div>
			</div>

			<p className="tldr primary-font-light">
			<br/>
			<br/>
			INTRODUCTION
			<br/>
			Thank you for visiting www.storie.media (The “Site”). Please read these terms and conditions carefully before using the Site. By using this Site, you agree to the following terms, conditions and privacy statement. Please do not use this Site if you do not agree to these terms and conditions of use. These terms and conditions are subject to change without notice. If you are a regular user of the Site, please review these terms and conditions. This Site is currently operated by Storie (sometimes referred to as "we", "our" or "us"). Laws/Regulations: User access and use of this Site is subject to all applicable international, federal, state and local laws and regulations.
			<br/>
			<br/>
			CONTENT
			<br/>
			This Site, including images, text, audio and other components therein ("Content") are copyrighted materials owned or licensed by us and are protected by worldwide copyright laws and treaty provisions. Elements of this Site are also protected by copyright, trade dress and other laws and may not be copied or imitated in whole or in part. Materials and elements, including, but not limited to, names, trademarks, logos, graphics, sounds or images may not be used, copied, reproduced, modified, published, uploaded, posted, transmitted, or distributed, in any way without our prior written permission. Except as expressly provided herein, we do not grant any express or implied right to users under any patents, copyright, trademarks, or trade secrets. The Content is intended for personal use only. Reproduction, sale, publishing, distribution, modification, display, reposting or other use of any portion of the Content in any other way or for any other purpose is not permitted without our prior written consent. You may download one copy of any portion of the Content for your personal, non-commercial home use only.
			<br/>
			<br/>
			LINKING
			<br/>
			If you operate a website, you may link to the Site. However, we reserve the right in our sole discretion to reject or terminate any links to the Site.

			We prohibit caching unauthorized hypertext links to this Site and the framing of any materials available through this Site. We reserve the right to disable any unauthorized links or frames and specifically disclaim any responsibility for the contents of any other Internet sites linked to this Site. Access to any other Internet sites linked to the site is at your own risk.

			You should be aware that linked sites may contain rules and regulations, privacy provisions, confidentiality provisions, transmission of personal data provisions, and other provisions that differ from the provisions provided on this Site. We are not responsible for such provisions, and expressly disclaim any and all liability related to such provisions.
			<br/>
			<br/>
			POSTINGS
			<br/>
			We reserve the right to delete, move or edit any message, posting or e-mail received which we deem in our sole discretion to be violative of this agreement, harmful to individuals or communities, obscene, defamatory, in violation of our rights or the rights of any third party, or is otherwise unacceptable or inappropriate for the Site.
			<br/>
			<br/>
			NO WARRANTY
			<br/>
			THIS SITE IS DISTRIBUTED ON AN "AS IS" BASIS. WE MAKE NO REPRESENTATIONS OR WARRANTIES REGARDING THE ACCURACY, RELIABILITY OR COMPLETENESS OF THE CONTENT OR THE CONTENT OF ANY SITE LINKED FROM THIS SITE. TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, NON-INFRINGEMENT, WARRANTIES OF TITLE, IMPLIED WARRANTIES OF QUALITY, PERFORMANCE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SECURITY AND ACCURACY. YOU ACKNOWLEDGE THAT ANY RELIANCE UPON ANY SUCH MATERIALS SHALL BE AT YOUR SOLE RISK. FURTHER, WE DO NOT WARRANT THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF COMPUTER VIRUSES.

			WE DO NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE INFORMATION, TEXT, GRAPHICS, LINKS, OR OTHER ITEMS CONTAINED WITHIN THIS SITE. THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THIS SITE COULD CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY MADE TO THE INFORMATION HEREIN. WE MAY MAKE IMPROVEMENTS AND/OR MAKE CHANGES IN THE PRODUCT(S) AND SERVICES DESCRIBED HEREIN AT ANY TIME. WE HAVE NO DUTY TO UPDATE THIS SITE OR THE CONTENT HEREOF AND WE SHALL NOT BE LIABLE FOR ANY FAILURE TO UPDATE SUCH INFORMATION. YOU AGREE TO RELEASE, HOLD HARMLESS AND INDEMNIFY US. FROM ANY AND ALL LIABILITY ARISING FROM INCORRECT OR INCOMPLETE INFORMATION IN THIS WEBSITE
			<br/>
			<br/>
			LIMITATION OF LIABILITY
			<br/>
			OU AGREE THAT WE AND ANY OF OUR OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS WILL NOT BE LIABLE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, FOR ANY INDIRECT, PUNITIVE, SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES (INCLUDING WITHOUT LIMITATION LOST PROFITS, COST OF PROCURING SUBSTITUTE SERVICE OR LOST OPPORTUNITY) ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS SITE, OR A LINKED SITE OR WITH THE DELAY OR INABILITY TO USE THE SITE OR A LINKED SITE, EVEN IF WE ARE MADE AWARE OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION ON LIABILITY INCLUDES, BUT IS NOT LIMITED TO, THE TRANSMISSION OF ANY VIRUSES WHICH MAY INFECT A USER’S EQUIPMENT, FAILURE OF MECHANICAL OR ELECTRONIC EQUIPMENT OR COMMUNICATION LINES, TELEPHONE OR OTHER INTERCONNECT PROBLEMS (e.g., YOU CANNOT ACCESS YOUR INTERNET SERVICE PROVIDER), UNAUTHORIZED ACCESS, THEFT, OPERATOR ERRORS, STRIKES OR OTHER LABOR PROBLEMS OR ANY FORCE MAJEURE. WE CANNOT AND DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE WEB SITE. FURTHERMORE, YOU AGREE TO INDEMNIFY US AGAINST LIABILITY FOR ANY AND ALL USE OF THE SITE...

			ANY PARTNER IDENTIFIED ON THIS SITE, INCLUDING WITHOUT LIMITATION, LIABILITY FOR ANY ACT, ERROR, OMISSION, INJURY, LOSS, ACCIDENT, DELAY OR IRREGULARITY WHICH MAY BE INCURRED THROUGH THE FAULT, NEGLIGENT OR OTHERWISE, OF SUCH BUSINESS AND YOU HEREBY EXONERATE US FROM ANY LIABILITY WITH RESPECT TO THE SAME.
			<br/>
			<br/>
			JURISDICTION AND VENUE
			<br/>
			This Site is controlled and operated by us from our office within the United States of America. Any claim relating to this Site shall be governed by the internal substantive laws of the state of California. You and us agree to submit to the personal and exclusive jurisdiction of the courts located within the state of California. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of this Site or these Terms and Conditions must be filed within one (1) year after such claim or cause of action arose.
			<br/>
			<br/>
			VIOLATION OF TERMS AND CONDITIONS
			<br/>
			We reserve the right to seek all remedies available at law and in equity for violations of these Terms and Conditions, including the right to block access from a particular Internet address to this Site.

			We operate this Site and reserve the right to change these terms and conditions at any time and from time to time without notice. Our failure to exercise or enforce any right or provision of these terms and conditions shall not constitute a waiver of such right or provision. The section titles herein are for convenience only and have no legal or contractual effect.

			Access to Password Protected/Secure Areas Access to and use of password protected and/or secure areas of this Site is restricted to authorized users only. Unauthorized individuals attempting to access these areas of this Site may be subject to prosecution.
			<br/>
			<br/>
			PRIVACY POLICY
			<br/>
			Please click <NavLink to='/privacy' className='link'>here</NavLink> to read our Privacy Policy If you have any questions regarding these terms and conditions of use, please contact: books.storie.media@gmail.com
			</p>

        </div>
        <Footer fixedBottom={true}/>
      </div>
    </>
  );
}

export default Landing;
