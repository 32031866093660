import React, { useState } from 'react';
import stringToColor from '../Utils/string'

const ResultCard = (props) => {
  const [loaded, setLoaded] = useState(false);
  const result = props.value
  const imageUrl = result.image.L
  const backgroundUrl = imageUrl
  const preloadBackgroundColor = stringToColor(result.title)
  const preloadBackgroundGradient = "linear-gradient(to bottom, #000000dd, #000000dd)"
  const preloadImageStyle = loaded ? null : {backgroundImage: preloadBackgroundGradient, backgroundColor: preloadBackgroundColor, borderRadius: '20px'}

  return (
    <div className="result-card" onClick={props.onClick} value={result}>
      <div className="result-image" style={preloadImageStyle}>
        <img src={backgroundUrl} alt=''
        onError={(event) => event.target.style.display = 'none'}
        onLoad={(event) => {
            setLoaded(true)
            event.target.style.display = 'inline-block';
          }}>
        </img>
      </div>
      <div className="primary-font-light">
        <span title={result.title}><p className="result-title">
          {result.title}
        </p></span>
        <span title={result.author}><p className="result-subtitle">
          {result.author}
        </p></span>
      </div>
    </div>
  );
};

export default ResultCard;
