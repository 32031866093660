/* eslint-disable max-len, sort-keys */
const genres = [
  {name: 'Action & Adventure'},
  {name: 'African American'},
  {name: 'Anthology'},
  {name: 'Art'},
  {name: 'Beauty'},
  {name: 'Biography & Autobiography'},
  {name: 'Business'},
  {name: "Children's"},
  {name: 'Classics'},
  {name: 'Comics & Graphic Novels'},
  {name: 'Crime'},
  {name: 'Diary'},
  {name: 'Drama'},
  {name: 'Economics'},
  {name: 'Entertainment'},
  {name: 'Erotica & Sexuality'},
  {name: 'Essays'},
  {name: 'Ethics'},
  {name: 'Family'},
  {name: 'Fantasy'},
  {name: 'Fiction'},
  {name: 'Film & Photography'},
  {name: 'Finance'},
  {name: 'Folklore & Mythology'},
  {name: 'Food & Cooking'},
  {name: 'Friendship'},
  {name: 'Health & Medical'},
  {name: 'Hispanic & Latino'},
  {name: 'Historical Fiction'},
  {name: 'History'},
  {name: 'Holiday'},
  {name: 'Home & Garden'},
  {name: 'Horror & Suspense'},
  {name: 'Humor'},
  {name: 'LGBTQIA+'},
  {name: 'Language'},
  {name: 'Literary Criticism'},
  {name: 'Military'},
  {name: 'Music'},
  {name: 'Mystery & Detective'},
  {name: 'Native American'},
  {name: 'Non-Fiction'},
  {name: 'Pets & Animals'},
  {name: 'Philosophy'},
  {name: 'Plays'},
  {name: 'Poetry'},
  {name: 'Politics & Government'},
  {name: 'Psychology'},
  {name: 'Race & Racism'},
  {name: 'Religion'},
  {name: 'Romance'},
  {name: 'School & Education'},
  {name: 'Science & Technology'},
  {name: 'Science Fiction'},
  {name: 'Self-Help'},
  {name: 'Short Stories'},
  {name: 'Social Science'},
  {name: 'Spiritual'},
  {name: 'Sports & Outdoors'},
  {name: 'Travel'},
  {name: 'Trigger Warning'},
  {name: 'Women'},
  {name: 'Young Adult'}
];

export default genres;