import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

const Landing = () => {
  return (
    <>
      <div className="App">
        <Header fixed={true}/>
        <div className="middle-bg"/>
        <div className="middle-scroll">
        	<p className="secondary-font title">Privacy Policy</p>
        	<br/>
        	<br/>
    		<div className="tldr primary-font-heavy">
    			<p className="subtitle">TL;DR</p>
    			<div className="bullet-list"> 
        			<div className="bullet">
	        			<ul>
	        				<li>We are not selling your data.</li>
	        			</ul>
        			</div>
        			<div className="bullet">
	        			<ul>
							<li>We are only analyzing search results to help us finetune what we should suggest as author, genre, and keyword terms on our search page.</li>
						</ul>
					</div>
				</div>
			</div>

			<p className="tldr primary-font-light">
			<br/>
			<br/>
			INTRODUCTION
			<br/>
			Thank you for visiting www.storie.media (the "Site"). Privacy is very important to Storie. It is our policy to respect the privacy of users who visit the Site. To ensure this, the following policy statement explains how we collect information from you as well as how it is used. We may update this policy from time to time, as new services and features are added to the Site, so please check back periodically for any changes.			<br/>
			<br/>
			OVERVIEW OF THE INFORMATION WE COLLECT
			<br/>
			Storie does not collect personally identifiable information about individuals except when such individuals specifically provide such information on a voluntary basis as part of a request for goods, services or information.

			When you access the Site, Storie automatically receives and records information from your browser including your IP Address, how long you look at features on the site, whether you click on advertising banners, request information or request goods or services through the Site. Storie collects personally identifiable information when you request goods or services from us, partners or other service providers featured on the site. We also collect personally identifiable information from you when you register with us, contact us, or seek information from us.

			The principal way in which we use your personal information is to aggregate it with that of all of other Site users so that we can improve the Site and make it more interesting and user-friendly. We may share this aggregated demographic information with our partners. Such aggregated demographic information is not linked to any personal information that can identify any individual person. We also use information collected in order to provide you with the information, goods and/or services you have requested. We maintain information collected for a reasonable period of time.

			We also collect information about your donations and information requests from our alliance partners, as well as information relating to customer satisfaction.			<br/>
			<br/>
			DISCLOSURE TO THIRD PARTIES
			<br/>
			Storie does not sell or rent your personally identifiable information to third parties. Storie only shares your personally identifiable information with third parties when: We have your consent to do so. We need to send the information to partners who will provide a product or service directly for you.

			We need to send the information to partners who work on behalf of Storie to provide a product or service for you. Examples include fulfilling orders, authenticating and clearing financial transactions, delivering packages, sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, processing credit card payments, and providing customer service. Unless we tell you differently, these partners do not have any right to use the personally identifiable information we provide to them beyond what is necessary to assist us unless they have your consent to do so.

			We need to respond to valid legal process. This includes a search warrant, subpoena, or court order, and any other instance when we believe we are required to do so by law. We may also make disclosures to protect your safety during emergencies if we reasonably believe your safety is at risk, to protect the security or integrity of the Site, or to take precautions against liability. We will take reasonable steps to limit the scope of these disclosures.

			Storie does not control and is not responsible for the privacy practices of other sites or third parties to whom you may link from the Site. Site users should also be aware that when they voluntarily disclose or give the permission to disclose personally identifiable information (user name, e-mail address) on a public area of the site, that information, along with any substantive information disclosed in the user's communication, can be collected and correlated and used by third parties and may result in unsolicited messages from other posters or third parties. Such activities are beyond the control of Storie.
			<br/>
			<br/>
			CONTACTS AND COMPLAINTS
			<br/>
			This Web site is operated by Storie. If you have a complaint or question about our privacy policy or about our implementation of the policy, please email us at: books.storie.media@gmail.com

			By using this Site, you consent to the collection, use, and transfer of your personal information as described in this privacy policy by Storie.
			</p>

        </div>
        <Footer fixedBottom={true}/>
      </div>
    </>
  );
}

export default Landing;
