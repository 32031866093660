import React, { useState } from 'react';
import doubleCaret from '../Assets/double-caret.svg';
import ResultsDetailsTabs from './ResultsDetailsTabs'
import { useQuery, QueryClient, QueryClientProvider } from 'react-query'
import escapeQueryTerm from '../Utils/query'
import stringToColor from '../Utils/string'

const queryClient = new QueryClient()
const REACT_APP_AUTHOR_DB_URL    	= process.env.REACT_APP_AUTHOR_DB_URL;
const elasticsearch 	= require('elasticsearch');
const client        	= new elasticsearch.Client({host: REACT_APP_AUTHOR_DB_URL});


const ResultDetails = (props) => {
	const searchParams = props.searchParams
	const selectedResult = props.selectedResult
	const selectedImage = selectedResult.image.L

	const [loaded, setLoaded] = useState(false);
	const preloadBackgroundColor = stringToColor(selectedResult.title)
  	const preloadBackgroundGradient = "linear-gradient(to bottom, #000000cc, #000000cc)"
  	const preloadImageStyle = loaded ? null : {backgroundImage: preloadBackgroundGradient, backgroundColor: preloadBackgroundColor, borderRadius: '20px'}

	const handleScroll = () => {
		let pageHeight = window.outerHeight;
		window.scrollBy(0, -10*pageHeight)
	}

	const { status, data } = useQuery(["author", selectedResult], 
		async () => {
			const authorName = selectedResult.author
			const response = await client.search({
				index: 'narro-production',
				type: 'author',
				body: {
					query: {
						match: {
							name: escapeQueryTerm(authorName)
						}
					}
				}
			})
			let authorResult = ''
			const results = response.hits.hits
			if (results) {
				authorResult = results[0]._source
			}
			return authorResult
		}
	);

	return (
		<QueryClientProvider client={queryClient} key="result-details-query">      
			<div className='result-details-container'>
				<div className='back-to-results primary-font-light' onClick={handleScroll}>
					<img src={doubleCaret} width="20" alt=""/>
				</div>
				<div className="result-details-title no-mobile">
					<span className="result-details-book-title primary-font-heavy">{selectedResult.title}</span>
					<span className="primary-font-light" style={{color: '#767676'}}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
					<span className="result-details-author primary-font-light">{selectedResult.author}</span>
				</div>
				<div className="result-details">

					<div className="result-details-title-mobile">
						<span className="result-details-book-title primary-font-heavy">{selectedResult.title}</span>
						<br/>
						<span className="result-details-author primary-font-light">{selectedResult.author}</span>
					</div>
					<div className="result-details-body">
						<div className="result-details-left">
					    	<div className="result-details-card">
					      		<div className="result-details-image" style={preloadImageStyle}>
					        		<img 
					        		src={selectedImage} 
					        		alt={'nothing'} 
					        		onError={(event) => event.target.style.display = 'none'}
							        onLoad={(event) => {
							            setLoaded(true)
							            event.target.style.display = 'inline-block';
							          }
							      	}>  	
						            </img>
					      		</div>
					      	</div>
						</div>
						<div className="result-details-right">
							<span className="results-details-tabs">
								<ResultsDetailsTabs selectedResult={selectedResult} author={data} key={'author'} authorFetchStatus={status} searchParams={searchParams}/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</QueryClientProvider>
	);
}

export default ResultDetails;