import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TypeaheadGenre from './TypeaheadGenre'
import TypeaheadAuthor from './TypeaheadAuthor'
import TypeaheadKeyword from './TypeaheadKeyword'
import SearchButton from './SearchButton'
import SearchFilters from './SearchFilters'
import ResultsGallery from './ResultsGallery'
import Toggle from './Toggle'
import Popup from './Popup';

const MAX_TERMS = 15

function getToggleName(name) {
  let toggleName;
  switch (name.toLowerCase()) {
    case 'genre':
      toggleName = 'matchAnyGenre'
      break;
    case 'author':
      toggleName = 'matchAnyAuthor'
      break;
    case 'keyword':
      toggleName = 'matchAnyKeyword'
      break;
    default:
      toggleName = '';
  }
  return toggleName;
}

const safeDocument = typeof document !== 'undefined' ? document : {};
const html = safeDocument.documentElement;
const { body } = safeDocument;
const blockScroll = () => {
  // if (!body || !body.style || scrollBlocked.current) return;
  if (!body || !body.style) return;

  const scrollBarWidth = window.innerWidth - html.clientWidth;
  const bodyPaddingRight =
    parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

  /**
   * 1. Fixes a bug in iOS and desktop Safari whereby setting
   *    `overflow: hidden` on the html/body does not prevent scrolling.
   * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
   *    scroll if an `overflow-x` style is also applied to the body.
   */
  html.style.position = 'relative'; /* [1] */
  html.style.overflow = 'hidden'; /* [2] */
  body.style.position = 'relative'; /* [1] */
  body.style.overflow = 'hidden'; /* [2] */
  body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

};


class SearchModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleGenreChange = this.handleGenreChange.bind(this);
    this.handleAuthorChange = this.handleAuthorChange.bind(this);
    this.handleKeywordChange = this.handleKeywordChange.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleApplyClick = this.handleApplyClick.bind(this);
    this.handleSearchFilterClick = this.handleSearchFilterClick.bind(this);
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.toggleMaxTermsPopup = this.toggleMaxTermsPopup.bind(this)
    this.state = {
      genres: [], 
      authors: [],
      keywords: [],
      showSearchPanels: true,
      matchAnyGenre: false,
      matchAnyAuthor: false,
      matchAnyKeyword: false,
      isMaxTermsPopupOpen: false
    };
  }
 
  toggleMaxTermsPopup = () => {
    this.setState({isMaxTermsPopupOpen: !this.state.isMaxTermsPopupOpen})
  }

  handleGenreChange(genres) {
    this.setState({genres});
    this.setState({
      matchAnyGenre: genres.length <= 1 ? false : this.state.matchAnyGenre
    })
  }
  handleAuthorChange(authors) {
    if (authors.length <= MAX_TERMS) {
      this.setState({authors});
      this.setState({
        matchAnyAuthor: authors.length <= 1 ? false : this.state.matchAnyAuthor
      })
    } else {
      this.toggleMaxTermsPopup()
    }
  }
  handleKeywordChange(keywords) {
    this.setState({keywords});
    this.setState({
      matchAnyKeyword: keywords.length <= 1 ? false : this.state.matchAnyKeyword
    })
  }
  handleSuggestionClick(e, suggestion, suggestionType) {
    const suggestionTerm = {name: suggestion}
    const termNames = this.state[suggestionType].map(term => term.name)
    if (!(termNames.includes(suggestion))) {
      const newTerms = [...this.state[suggestionType], ...[suggestionTerm]]

      if (newTerms.length <= MAX_TERMS) {
        this.setState({[suggestionType]: newTerms})
      } else {
        this.toggleMaxTermsPopup()
      }
      
    }
  }
  handleTabClick() {
    this.setState({showSearchPanels: true});
    blockScroll()
  }  
  handleApplyClick() {
    this.setState({showSearchPanels: false});
  }
  handleSearchFilterClick(e) {
    const clickedValue = e.target.value
    if (clickedValue) {
      // debugger
      const lastHyphenIndex = clickedValue.lastIndexOf('-');
      const searchTerm = clickedValue.substr(0, lastHyphenIndex)
      const searchType = clickedValue.substr(lastHyphenIndex + 1)
      const searchTerms = this.state[searchType]
      // searchType is plural (authors, genres, keywords) so we need to use the singular (author, genre, keyword)
      const toggleName = getToggleName(searchType.substring(0, searchType.length - 1));
      if (searchTerms) {
        const newSearchTerms = searchTerms.filter(item => item.name !== searchTerm);
        const shouldShowSearchPanels = (this.state.genres.length + this.state.authors.length + this.state.keywords.length - searchTerms.length + newSearchTerms.length) === 0
        this.setState({[searchType]: newSearchTerms})
        this.setState({[toggleName]: newSearchTerms.length <= 1 ? false : this.state[toggleName]})
        this.setState({showSearchPanels: shouldShowSearchPanels});
        if (shouldShowSearchPanels) {
          blockScroll()
        }
      } 

      this.setState({isNewQuery: true})

    }
  }

  handleToggleClick(event, toggleName) {
    this.setState({
      [toggleName]: !this.state[toggleName]
    });
  }

  render() {
    const tabMetadata = {
      "Genre": {
        color: "#ffffff44",
        text: "white",
        description: <><span className="primary-font-heavy mobile-font">POPULAR</span><span>: &nbsp;&nbsp; <span className='suggestion suggestion-genre mobile-font' onClick={((e) => this.handleSuggestionClick(e, 'Non-Fiction', 'genres'))}>Non-Fiction</span> &nbsp;&nbsp; <span className='suggestion suggestion-genre mobile-font' onClick={((e) => this.handleSuggestionClick(e, 'Romance', 'genres'))}>Romance</span> &nbsp;&nbsp; <span className='suggestion suggestion-genre mobile-font' onClick={((e) => this.handleSuggestionClick(e, 'Poetry', 'genres'))}>Poetry</span></span></>,
        borderRadius: "20px 0 0 0",
        borderRadiusCollapsed: "20px 0 0 20px",
        typeahead: <TypeaheadGenre onGenreChange={this.handleGenreChange} genres={this.state.genres} matchAny={this.state.matchAnyGenre}/>,
        numSelected: this.state.genres.length      
      },
      "Author": {
        color: "#ffffff44",
        text: "white",
        description: <><span className="primary-font-heavy mobile-font">POPULAR</span><span>: &nbsp;&nbsp; <span className='suggestion suggestion-author mobile-font' onClick={((e) => this.handleSuggestionClick(e, 'African-American', 'authors'))}>African-American</span> &nbsp;&nbsp; <span className='suggestion suggestion-author mobile-font' onClick={((e) => this.handleSuggestionClick(e, 'LGBTQIA+', 'authors'))}>LGBTQIA+</span> &nbsp;&nbsp;</span></>,
        borderRadius: "0 0 0 0",
        borderRadiusCollapsed: "0 0 0 0",
        typeahead: <TypeaheadAuthor onAuthorChange={this.handleAuthorChange} authors={this.state.authors} matchAny={this.state.matchAnyAuthor}/>,
        numSelected: this.state.authors.length
      },
      "Keyword": {
        color: "#ffffff44",
        text: "white",
        description: <><span className="primary-font-heavy mobile-font">POPULAR</span><span>: &nbsp;&nbsp; <span className='suggestion suggestion-keyword mobile-font' onClick={((e) => this.handleSuggestionClick(e, 'Nature', 'keywords'))}>Nature</span> &nbsp;&nbsp; <span className='suggestion suggestion-keyword mobile-font' onClick={((e) => this.handleSuggestionClick(e, 'Love', 'keywords'))}>Love</span> &nbsp;&nbsp; <span className='suggestion suggestion-keyword mobile-font' onClick={((e) => this.handleSuggestionClick(e, 'Technology', 'keywords'))}>Technology</span></span></>,
        borderRadius: "0 20px 0 0",
        borderRadiusCollapsed: "0 20px 20px 0",
        typeahead: <TypeaheadKeyword onKeywordChange={this.handleKeywordChange} keywords={this.state.keywords} matchAny={this.state.matchAnyKeyword}/>,
        numSelected: this.state.keywords.length
      },
    };

    const tabs = [];
    const tabPanels = [];

    Object.keys(tabMetadata).forEach(name => {

      const {text: color, description, borderRadius, borderRadiusCollapsed, typeahead, numSelected } = tabMetadata[name];

      // CHECKBOX
      const toggleName = getToggleName(name)
      const tabCheckbox = <div className='search-any'>
        Show results matching&nbsp;
        <Toggle handleToggleClick={((e) => this.handleToggleClick(e, toggleName))} type={name.toLowerCase()} toggle={this.state[toggleName]} name={toggleName} key={toggleName} className='primary-font-medium' leftLabel="ALL" rightLabel="ANY"/>
        &nbsp;of these terms
      </div>



      const circleClass = this.state[toggleName] ? `title-circle any ${name.toLowerCase()} primary-font-medium` : `title-circle ${name.toLowerCase()} primary-font-medium`
      const tabTitle = (numSelected > 0) ? <p>{name}&nbsp;&nbsp;<span className={circleClass}>{numSelected}</span></p> : <p>{name}</p>
      const tabClasses = this.state.showSearchPanels ? "search-tab secondary-font" : "search-tab--selected secondary-font"
      const tabStyle = this.state.showSearchPanels ? { borderRadius } : { borderRadius: borderRadiusCollapsed }
      tabs.push(
        <Tab style={tabStyle} className={tabClasses} key={name} onClick={this.handleTabClick}>
          {tabTitle}
        </Tab>
      );


      const tabPanelStyle = this.state.showSearchPanels ? { color } : { display: 'hidden', height: '0px', padding: '0' }
      const tabPanelClasses = "search-tab-panel primary-font-light"
      const tabPanelContent = 
        <div>
          <br/>
          {description}
          {typeahead}
          {numSelected > 1 ? tabCheckbox  : ''}
          {((this.state.genres.length + this.state.authors.length + this.state.keywords.length) > 0) ? 
            <SearchButton onClick={this.handleApplyClick} key={`search-button-${name}`} searchClass={`no-hover search-button primary-font-heavy search-button-${name.toLowerCase()}`} title='SEARCH'/> : 
            <SearchButton searchClass="search-button-disabled primary-font-medium" title='Select 1 Genre, Author, or Keyword'/>
          }
        </div>

      tabPanels.push(
        <TabPanel style={tabPanelStyle} className={tabPanelClasses} key={name} forceRender={true}>
          {this.state.showSearchPanels ? tabPanelContent : null}
        </TabPanel>         
      );
    });

    const searchModal = 
      <div>
        <Tabs
          selectedTabClassName="search-tab--selected"
          selectedTabPanelClassName="search-tab-panel--selected"
          defaultIndex={1}
        >
          <TabList className="search-tab-list">{tabs}</TabList>
          {tabPanels}
        </Tabs>
        {this.state.showSearchPanels ? null : <SearchFilters
            genres={this.state.genres}
            authors={this.state.authors}
            keywords={this.state.keywords}
            matchAnyGenre={this.state.matchAnyGenre}
            matchAnyAuthor={this.state.matchAnyAuthor}
            matchAnyKeyword={this.state.matchAnyKeyword}
            onClick={this.handleSearchFilterClick}
          />
        }
        {this.state.showSearchPanels ? null : <ResultsGallery searchParams={this.state}/>}
        {this.state.isMaxTermsPopupOpen && <Popup
          content={<>
            <b>Max Search Terms Applied</b>
            <p>No more than {MAX_TERMS} search terms allowed.</p>
          </>}
          handleClose={this.toggleMaxTermsPopup}
        />}
      </div>

    return (
      searchModal
    )
  }
}

export default SearchModal;
