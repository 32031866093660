import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './Components/AppRoute';
import Loader from './Components/Loader';
import routes from './Config/routes.js';

import './App.scss';
import './Styles/General.css';
import './Styles/MobileGeneral.css';

// Get the viewport height and multiply it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then set the custom --vh value to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // Update the element's size
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
          {routes.map((route, index) => (
            <AppRoute
              exact
              key={index}
              {...route}
            />
          ))}
      </Switch>
    </Suspense>
  );
}

export default App;
