import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import useScrollBlock from '../../Hooks/useScrollBlock'

const About = () => {
  const allowScroll = useScrollBlock()[1];
  allowScroll()
  return (
    <>
      <div className="mobile-bg">
        <Header fixed={false}/>
        <div className="search-page">
          <div className="about-top-bg is-hidden-mobile"/>
          <div className="about-bottom-bg is-hidden-mobile"/>
          <div className="mobile-bg no-desktop"/>
          <div className='about-intro'>
            <div className='about-inner'>
            <div className='about-circle'></div>
            <br/>
            <hr  style={{width: '50%', margin: 'auto', height: '1px'}}/>
            <br/>
            <p className="primary-font-light" style={{fontSize: '16px', margin: 'auto', 'textAlign': 'left'}}>
              Hi, I'm David Newcomb Morales (he/him), a Puerto Rican-American book reader and software engineer. I founded Storie on the traditional territory of the <a className='about-link' href='https://native-land.ca/maps/territories/chumash/' target='_blank' rel="noreferrer">Tongva</a>, <a className='about-link' href='https://native-land.ca/maps/territories/chumash/' target='_blank' rel="noreferrer">Chumash</a>, <a className='about-link' href='https://native-land.ca/maps/territories/chumash/' target='_blank' rel="noreferrer">Tataviam</a> and <a className='about-link' href='https://native-land.ca/maps/territories/chumash/' target='_blank' rel="noreferrer">Kizh</a>.
              <br/>
              <br/>
              I was motivated to start Storie because my personal book collection was heavily skewed toward Western, white, male authors. Most of the book recommendations I received were part of this category and I later learned the U.S. publishing industry is, unsurprisingly, <a className='about-link' href='https://blog.leeandlow.com/2020/01/28/2019diversitybaselinesurvey/' target='_blank' rel="noreferrer">very white</a>. To help diversify bookshelves for myself and others, I wanted to create a centralized online resource specifically promoting underrepresented storytellers.
              <br/>
              <br/>
              I'm still at the start of this journey, and Storie is a work in progress. I keep finding community advocates who do amazing work promoting diverse storytellers. My <NavLink to='/resources' className='about-link primary-text-color'>Resources</NavLink> page is an incomplete, but growing directory of these initiatives. If there's a resource that delivers what you're looking for, please check them out and support their work.
              <br/>
              <br/>
              If you want to reach out, use <span className='email'>books.storie.media@gmail.com</span> to send us a message. Thank you for your time, and I hope Storie helps you along your path.
            </p>
            <br/>
            <br/>
            </div>
          </div>
            <br className='no-mobile'/>
            <br className='no-mobile'/>
            <br className='no-mobile'/>
            <br className='no-mobile'/>
            <div className='faq'>
              <br className='no-mobile'/>
              <br className='no-mobile'/>
              <p className="secondary-font title" style={{fontSize: '35px', 'textAlign': 'center'}}>
                FAQ's
              </p>
              <hr  style={{width: '50%', margin: 'auto', height: '1px'}}/>
              <br/>
              <br/>
              <p className="secondary-font title">
                What is Storie?
              </p>
              <p className="primary-font-light">
                Storie is a search platform for finding and supporting diverse storytellers. Many groups have been historically excluded from publishing their works, and this website aims to highlight and uplift those groups.
              </p>
              <br/>
              <br/>
              <p className="secondary-font title">
                How does Storie pay the bills?
              </p>
              <p className="primary-font-light">
                Our income comes primarily from affiliate marketing. This means if you buy a book through one of our links to Bookshop or Amazon, we may receive a commission. Since Bookshop is friendlier to both authors and independent bookstores, they are our primary partner. If a book is unavailable through them, we'll use Amazon as a backup option.<br/><br/>If you want to support our mission, you can also donate to our organization at <a className='about-link' href='https://www.buymeacoffee.com/storie' target='_blank' rel="noreferrer">Buy Me a Coffee</a>. Donations received through this channel will also be used in the 50/50 split manner described below.
              </p>
              <br/>
              <br/>
              <p className="secondary-font title">
                What does our income support?
              </p>
              <p className="primary-font-light">
                50% of Storie's revenue goes to operational costs, and the other 50% goes directly toward underrepresented authors. At the end of each calendar year, this 50% amount will be donated to grants supporting underrepresented authors. Recipients will be 501(c)(3) non-profits such as <a className='about-link' href='https://diversebooks.org/we-need-diverse-books-launches-new-internship-grants-for-adult-publishing/' target='_blank' rel="noreferrer">We Need Diverse Books</a>.
              </p>
              <br/>
              <br/>
              <p className="secondary-font title">
                Where do we get our data?
              </p>
              <p className="primary-font-light">
                We use publically available data for books and authors from OpenLibrary and Wikipedia. We also try to manually expand our dataset by searching for authors and books not found in these datasets. We're continually working to both correct errors in our data and cover blindspots. 
              </p>
              <br/>
              <br/>
              <p className="secondary-font title">
                What is our roadmap?
              </p>
              <p className="primary-font-light">
                Right now, Storie is primarily focused on contemporary, U.S. based authors. Our data goal is to expand to support several languages, countries and centuries. If there's enough interest, we may also expand the tool for users to be able to save favorite books, keep track of their reading, and make guided recommendations.
              </p>
              <br/>
              <br/>
              <p className="secondary-font title">
                What is our logo?
              </p>
              <p className="primary-font-light">
                The intertwined spiral is based on the Taíno symbol for water. The Taíno are the native inhabitants of Borinquen, now known as Puerto Rico.
              </p>
              <br/>
              <br/>
            </div>
        </div>    
        <Footer fixedBottom={false}/>
      </div>
    </>
  );
}

export default About;
