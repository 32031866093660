import React from 'react';
import { NavLink } from 'react-router-dom';
import heart from '../../Assets/heart.svg';
import Header from '../../Components/Header';

const Landing = () => {
  return (
    <>
      <div className="App">
        <Header fixed={true}/>
        <div className="App-bg">
          <header className="App-header">
            <p className="is-hidden-touch">
              Buy books from diverse storytellers.
            </p>
            <p className="is-hidden-desktop p-2">
              Buy books from diverse storytellers.
            </p>
            <NavLink to='/search' className='button is-primary is-size-5 m-6'>
              <img src={heart} width="23" height="23" className="mr-3" alt=""/>
              Explore Storytellers
            </NavLink>
          </header>
        </div>
      </div>
    </>
  );
}

export default Landing;
