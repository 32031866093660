// import React from 'react';
import Landing from '../Pages/Landing';
import Search from '../Pages/Search';
import About from '../Pages/About';
import Resources from '../Pages/Resources';
import Contact from '../Pages/Contact';
import Terms from '../Pages/Terms';
import Privacy from '../Pages/Privacy';

// const LazySearch = React.lazy(() => import('../Pages/Search'));

const routes =[
  {
    path:'/',
    component: Landing,
    isPrivate: false
  },
  {
    path:'/search',
    component: Search,
    isPrivate: false
  },
  {
    path:'/about',
    component: About,
    isPrivate: false
  },
  {
    path:'/resources',
    component: Resources,
    isPrivate: false
  },
  {
    path:'/contact',
    component: Contact,
    isPrivate: true
  },
  {
    path:'/terms',
    component: Terms,
    isPrivate: false
  },
  {
    path:'/privacy',
    component: Privacy,
    isPrivate: false
  }
]

export default routes;
